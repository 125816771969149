import React from 'react'
import { Input, InputGroup, FormHelperText, FormLabel, FormControl, Button, ResponsiveValue, Flex } from '@chakra-ui/react'
import { formColors } from '../../../utils/colors'
import { field, inputWidth, inputHeight } from '../../../utils/ui'
import { CountrySelector, usePhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

interface IPhoneFieldProps {
  placeholder?: string
  size?: ResponsiveValue<string> | undefined
  addClass?: string
  name: string
  helperText?: string
  label?: string
  id?: string
  isDisabled?: boolean
  value?: string
  defaultValue?: string
  onChange: (phone: string) => void
  onFocus?: any
  onBlur?: any
  isInvalid?: boolean
  isRequired?: boolean
  isReadOnly?: boolean
}

const PhoneField = ({ placeholder, addClass, name, helperText, label, id, isDisabled, onChange, isInvalid = false, value, onFocus, onBlur, isReadOnly, isRequired }: IPhoneFieldProps) => {
  const phoneInput = usePhoneInput({
    defaultCountry: 'mx',
    value,
    onChange: (data) => {
      onChange(data.phone)
    }
  })

  return (
    <FormControl
      zIndex='sticky'
      width={inputWidth}
      minH={field.inputContainerHeight}
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    >
      <FormLabel opacity={0.5}>
        {label} {isRequired ? '*' : ''}
      </FormLabel>
      <InputGroup
        zIndex='sticky'
        width='100%'
        flexGrow={1}
      >
        <Flex
          w='100%'
          alignItems='center'
          position='relative'
          zIndex='sticky'
        >
          <CountrySelector
            selectedCountry={phoneInput.country.iso2}
            onSelect={(country) => phoneInput.setCountry(country.iso2)}
            renderButtonWrapper={({ children, rootProps }) => (
              <Button
                {...rootProps}
                variant='outline'
                px='10px'
                mr='8px'
              >
                {children}
              </Button>
            )}
          />
          <Input
            height={inputHeight}
            width='100%'
            flexGrow={1}
            name={name}
            type='tel'
            color='primary'
            value={phoneInput.inputValue}
            onChange={phoneInput.handlePhoneValueChange}
            ref={phoneInput.inputRef}
            errorBorderColor={formColors.error.border}
            focusBorderColor={formColors.default.focus.border}
            className={addClass}
            isInvalid={isInvalid}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            isReadOnly={isReadOnly}
          />
        </Flex>
      </InputGroup>
      <FormHelperText height={2}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export { PhoneField }
