import React, { useState } from 'react'
import { Input, InputGroup, FormHelperText, FormLabel, FormControl, InputLeftElement, Button, InputRightAddon, ResponsiveValue } from '@chakra-ui/react'
import { FaEye } from 'react-icons/fa'
import { colors, formColors } from '../../utils/colors'
import { field, inputHeight, inputWidth, inputContainerHeight } from '../../utils/ui'

interface ITextFieldProps {
  placeholder?: string
  size?: ResponsiveValue<string> | undefined
  addClass?: string
  type?: 'text' | 'password' | 'number'
  name?: string
  helperText?: string
  label?: string
  id?: string
  leftChildren?: React.JSX.Element | undefined | null
  rightChildren?: React.JSX.Element | undefined | null
  isDisabled?: boolean
  value?: string | number | undefined
  defaultValue?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: any
  onBlur?: any
  isInvalid?: boolean
  isRequired?: boolean
  limit?: number
  isReadOnly?: boolean
  onKeyDownCapture?: React.KeyboardEventHandler<HTMLInputElement> | undefined
}

const TextField = ({ placeholder, size = 'md', addClass, type = 'text', name, helperText, label, id, leftChildren, rightChildren, isDisabled, onChange, isInvalid = false, value, onFocus, onBlur, limit, isReadOnly, isRequired, onKeyDownCapture }: ITextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <FormControl
      width={inputWidth}
      minH={field.inputContainerHeight}
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    >
      <FormLabel opacity={0.5}>
        {label} {isRequired ? '*' : ''}
      </FormLabel>
      <InputGroup>
        {leftChildren && <InputLeftElement>{leftChildren}</InputLeftElement>}
        <Input
          onKeyDownCapture={onKeyDownCapture}
          height={inputHeight}
          isReadOnly={isReadOnly}
          isInvalid={isInvalid}
          id={id}
          value={value}
          size={size}
          name={name}
          errorBorderColor={formColors.error.border}
          focusBorderColor={formColors.default.focus.border}
          className={addClass}
          placeholder={placeholder}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          isDisabled={isDisabled}
          onChange={(e) => {
            if (limit && e.target.value.length > limit) return
            else if (onChange) onChange(e)
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          _focus={{
            borderColor: 'none'
          }}
        />

        {type === 'password' && (
          <InputRightAddon
            backgroundColor={showPassword ? formColors.default.hover : colors.gray88}
            onClick={() => setShowPassword(!showPassword)}
            p='0'
          >
            <Button
              borderRightRadius='sm'
              borderTopLeftRadius='none'
              borderBottomLeftRadius='none'
              variant='solid'
              size='sm'
              minH='full'
              _hover={{
                backgroundColor: showPassword ? formColors.default.hover.backgroundColor : colors.gray88
              }}
              backgroundColor={showPassword ? formColors.default.hover.backgroundColor : colors.gray88}
              onClick={() => setShowPassword(!showPassword)}
            >
              <FaEye />
            </Button>
          </InputRightAddon>
        )}

        {rightChildren}
      </InputGroup>
      <FormHelperText height={2}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export { TextField }
