import { Flex, FlexProps, SimpleGrid, Skeleton, useBoolean } from '@chakra-ui/react'
import React from 'react'
import { containerCardStyles, formatCurrencyAmount } from '../../utils/ui'
import { MdReceipt, MdGroup, MdMoney } from 'react-icons/md'
import { Text } from '../fragments/Text'
import { colors } from '../../utils/colors'
import { HiIdentification } from 'react-icons/hi'
import { useAppSelector } from '../../store/hooks'
import moment from 'moment'
import { AnalyticsService } from '../../services/Analytics'
import { DateValue } from '../fields/Date/DateRangePicker'
import { GetStatsResponse } from '../../ts/interfaces/request'
import { UserService } from '../../services/User'
import { isValidDateRange } from '../../utils/date'
import { ExpensesFilterBy } from '../../ts/interfaces/expenses'
import _ from 'lodash'
interface IStatCard {
  title: string | number
  body: string | number
  icon: any
  isLoading?: boolean
}

const StatCard = React.memo(({ body, title, icon, isLoading }: IStatCard) => {
  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex
        flexDir='column'
        {...containerCardStyles}
      >
        <SimpleGrid
          placeItems='center'
          templateColumns='0.9fr 0.1fr'
        >
          <Text
            textAlign='start'
            w='100%'
          >
            {title}
          </Text>
          {icon}
        </SimpleGrid>
        <Flex
          flexWrap='wrap'
          w='100%'
        >
          <Text
            fontSize={{
              base: '1.6rem',
              md: '1.7rem',
              xl: '2rem'
            }}
            fontWeight={600}
          >
            {body}
          </Text>
        </Flex>
      </Flex>
    </Skeleton>
  )
})

interface IStatsProps extends FlexProps {
  dateRange: DateValue
  filterBy: ExpensesFilterBy
}

const Stats = React.memo(({ dateRange, filterBy, ...props }: IStatsProps) => {
  const invoices = useAppSelector((state) => state.invoices)
  const totalEnabledTaxInformation = React.useMemo(() => {
    return invoices.taxInformation.filter(({ deleted_at }) => (!deleted_at ? true : false))
  }, [invoices])

  const organization = useAppSelector((state) => state.organization)
  const totalEnabledUsers = React.useMemo(() => {
    return organization.users.filter(({ deleted_at }) => (!deleted_at ? true : false))
  }, [organization])

  const [data, setData] = React.useState<GetStatsResponse['data']>({
    total_receipts: 0,
    total_spent: 0
  })

  const [usersIsLoading, toggleUsersIsLoading] = useBoolean(false)
  const [dataIsLoading, toggleDataIsLoading] = useBoolean(false)

  const fetchData = async () => {
    if (!isValidDateRange(dateRange)) return
    toggleDataIsLoading.on()
    const response = await AnalyticsService().getStats({
      start_date: dateRange[0],
      end_date: dateRange[1],
      filter_by: filterBy
    })
    setData({ ...response })
    toggleDataIsLoading.off()
  }

  React.useEffect(() => {
    ;(async () => {
      toggleUsersIsLoading.on()
      await UserService().getAllUsers()
      toggleUsersIsLoading.off()
    })()
  }, [])

  React.useEffect(() => {
    fetchData()
  }, [dateRange, filterBy])

  return (
    <SimpleGrid
      columns={{
        base: 2,
        md: 4
      }}
      columnGap={{
        base: '0.5rem',
        md: '1rem'
      }}
      rowGap={{
        base: '1rem'
      }}
      placeContent='space-between'
      {...props}
    >
      <StatCard
        isLoading={dataIsLoading}
        title='# de tickets'
        icon={<MdReceipt />}
        body={`#${data.total_receipts}`}
      />
      <StatCard
        isLoading={dataIsLoading}
        title='Total de gastos'
        icon={<MdMoney />}
        body={formatCurrencyAmount(data.total_spent)}
      />
      <StatCard
        isLoading={usersIsLoading}
        title='Número de usuarios'
        icon={<MdGroup />}
        body={totalEnabledUsers.length}
      />
      <StatCard
        isLoading={usersIsLoading}
        title='Número de razones sociales'
        icon={<HiIdentification />}
        body={totalEnabledTaxInformation.length}
      />
    </SimpleGrid>
  )
})

export { Stats }
