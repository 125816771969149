import React from 'react'
import { TextField } from '../components/fields'
import { Button } from '../components/fragments/Button'
import { Title } from '../components/fragments/Title'
import { AbsoluteCenter, useToast, Flex, Text, Link as ChakraLink } from '@chakra-ui/react'
import * as Yup from 'yup'
import { useForm } from '../hooks/useForm'
import { AuthService } from '../services/Auth'
import { AxiosError } from 'axios'
import { signIn } from '../store/user/slice'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { routerObj } from '../components/routing/Router'
import { rounded, padding } from '../utils/ui'
import { colors } from '../utils/colors'
//@ts-ignore
import ZummaBannerLogo from '../assets/svg/ZummaBannerLogo.svg'
import { ReactSVG } from 'react-svg'
import { SubscriptionService } from '../services/Subscription'

const SigninSchema = Yup.object().shape({
  password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required')
})

const SignIn = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()

  const formik = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: SigninSchema,
    onSubmit: async (values) => {
      try {
        formik.handleSetTouchedForm()
        const response = await AuthService().signIn({
          email: values.email.trim(),
          password: values.password.trim()
        })
        dispatch(
          signIn({
            id: response.data.id,
            email: response.data.email,
            token: response.session.access_token,
            refresh_token: response.session.refresh_token,
            phone_numbers: response.data.phone_numbers,
            role: response.data.role,
            name: response.data.name || ''
          })
        )
        await SubscriptionService().getSubscriptionStatus()
        navigate(routerObj.home.path)
      } catch (error) {
        const errorStatus = (error as AxiosError).request.status as number
        if (errorStatus === 400) {
          formik.setFieldError('email', 'Email inválido')
        }
        const messages: Record<string, string> = {
          '404': 'Usuario no existe',
          '401': 'Credenciales incorrectas'
        }
        toast({
          title: messages[`${errorStatus}`] ? messages[`${errorStatus}`] : `Hubo un error ${(error as AxiosError).message}`,
          status: 'error',
          duration: 8000,
          isClosable: true
        })
      }
    }
  })

  return (
    <AbsoluteCenter
      width={{
        base: 'full',
        md: 'max-content'
      }}
      axis='both'
      bg='white'
      py={padding.container.py}
      px={padding.container.px}
      rounded={rounded.container}
    >
      <Flex
        justifyContent='center'
        mb='1rem'
      >
        <ReactSVG src={ZummaBannerLogo} />
      </Flex>
      <form
        className='flex flex-col items-center gap-[0.8rem] pb-[1rem]'
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
      >
        <Title className='text-center mb-[1rem]'>Iniciar sesión</Title>
        <TextField
          isReadOnly={formik.isSubmitting}
          isRequired={true}
          label='Correo Electrónico'
          name='email'
          id='email'
          type='text'
          isDisabled={false}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.email && formik.errors.email ? true : false}
        />

        <TextField
          isReadOnly={formik.isSubmitting}
          isRequired={true}
          label='Contraseña'
          name='password'
          id='password'
          type='password'
          isDisabled={false}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.password && formik.errors.password ? true : false}
        />

        <Button
          mt={4}
          type='submit'
          isLoading={formik.isSubmitting}
          id='submitFormButton'
        >
          Iniciar sesión {'>>'}
        </Button>
        <Button
          id='signUpFormButtom'
          isLoading={formik.isSubmitting}
          borderStyle='dashed'
          bg='transparent'
          borderColor={colors.text[4]}
          textColor={colors.text[2]}
          _hover={{
            borderColor: colors.text[1]
          }}
          _active={{
            bg: 'transparent'
          }}
          onClick={() => navigate(routerObj.signUp.path)}
        >
          {'<<'} Registro
        </Button>
      </form>

      <Flex
        flexDir='column'
        alignItems='center'
      >
        <Text
          mt='1rem'
          gap='.55rem'
          fontWeight={600}
          textColor={colors.tertiary[1]}
          _hover={{
            textDecor: 'underline'
          }}
        >
          <Link
            id='recoverPasswordLink'
            to={routerObj.recoverPassword.path}
          >
            Recuperar Contraseña
          </Link>
        </Text>
        <Text
          mt='1rem'
          gap='.55rem'
          fontWeight={600}
          textColor={colors.tertiary[1]}
        >
          <ChakraLink
            target='_blank'
            href={process.env.REACT_APP_HELP_URL}
          >
            ¿Necesitas ayuda? Contáctanos
          </ChakraLink>
        </Text>
        <Text
          mt='1rem'
          gap='.55rem'
          fontWeight={600}
          textColor={colors.tertiary[1]}
        >
          <ChakraLink
            target='_blank'
            href='https://www.youtube.com/watch?v=0U2mHw9bDYU'
          >
            ¿Quieres conocer más? Ve este video
          </ChakraLink>
        </Text>
      </Flex>
    </AbsoluteCenter>
  )
}

export { SignIn }
