import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalProps, Progress, SimpleGrid, useBoolean, useToast } from '@chakra-ui/react'
import { Button } from '../fragments/Button'
import React from 'react'
import { useForm } from '../../hooks/useForm'
import * as Yup from 'yup'
import { TextField, SelectField } from '../fields'
import { DateField } from '../fields/Date/DateField'
import { Expense, CategoryOptions } from '../../ts/interfaces/expenses'
import { ExpensesService } from '../../services/Expenses'
import { FaPencil } from 'react-icons/fa6'

const ExpenseSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  amount: Yup.number().required('Required'),
  date: Yup.date().required('Required')
})

interface IUpdateExpenseModalProps extends Omit<ModalProps, 'children'> {
  expenseId?: number | null
  handleRetrieveData: () => Promise<void>
}

export interface IExpenseForm extends Pick<Expense, 'name' | 'category' | 'amount' | 'date'> {}

const UpdateExpenseModal = React.memo(({ expenseId, handleRetrieveData, isOpen, onClose, ...props }: IUpdateExpenseModalProps) => {
  const [isFetching, toggleIsFetching] = useBoolean(false)
  const [initialData, setInitialData] = React.useState<IExpenseForm>({
    name: '',
    category: '',
    amount: 0,
    date: new Date()
  })
  const toast = useToast()

  const retrieveExpenseData = React.useCallback(async () => {
    toggleIsFetching.on()
    const response = await ExpensesService().getExpenseStatus(expenseId as number)
    const { expense } = response
    setInitialData(() => ({
      amount: expense.amount,
      category: expense.category,
      date: new Date(expense.date),
      name: expense.name
    }))
    toggleIsFetching.off()
  }, [expenseId, toggleIsFetching, ExpensesService, setInitialData])

  React.useEffect(() => {
    if (isOpen) retrieveExpenseData()
  }, [isOpen])

  const handleSubmit = async (formValues: IExpenseForm) => {
    try {
      toggleIsFetching.on()
      await ExpensesService().updateExpense(expenseId as number, formValues)
      toast({
        title: 'Gasto actualizado.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      toggleIsFetching.off()
      onClose()
      // Handle form submission
      await handleRetrieveData()
    } catch (_) {}
  }

  const form = useForm({
    initialValues: initialData,
    validationSchema: ExpenseSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true
  })

  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={!isFetching}
      isOpen={isOpen}
      isCentered
      onClose={() => {
        setInitialData(() => ({
          name: '',
          category: '',
          amount: 0,
          date: new Date()
        }))
        onClose()
      }}
      size={{
        base: '100%',
        md: '80%',
        xl: '50%'
      }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent
        maxW={{
          base: '100%',
          md: '80%',
          xl: '50%'
        }}
      >
        <ModalHeader>
          Modificar gasto
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={form.handleSubmit}
            className='flex flex-col w-[100%] pt-[0.5rem] pb-[1rem] items-center justify-center'
          >
            <SimpleGrid
              columns={{
                base: 1,
                md: 2
              }}
              w='100%'
              mb='1rem'
            >
              <TextField
                label='Comercio'
                name='name'
                id='name'
                value={form.values.name}
                isReadOnly={isFetching}
                onChange={form.handleChange}
                isInvalid={form.touched.name && form.errors.name ? true : false}
              />
              <SelectField
                options={CategoryOptions}
                label='Categoría'
                name='category'
                id='category'
                value={form.values.category}
                isDisabled={isFetching}
                onChange={form.handleSelectChange}
                isInvalid={form.touched.category && form.errors.category ? true : false}
              />
              <TextField
                label='Monto'
                name='amount'
                type='number'
                id='amount'
                value={form.values.amount}
                isReadOnly={isFetching}
                onChange={form.handleChange}
                isInvalid={form.touched.amount && form.errors.amount ? true : false}
              />
              <DateField
                isInvalid={form.touched.date && form.errors.date ? true : false}
                label='Fecha'
                name='date'
                id='date'
                value={form.values.date}
                isReadOnly={isFetching}
                onChange={(value) => {
                  form.setFieldValue('date', value)
                }}
              />
            </SimpleGrid>

            <Button
              type='submit'
              id='updateExpenseSubmitButton'
              rightIcon={<FaPencil />}
              isLoading={isFetching}
            >
              Modificar
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})

export { UpdateExpenseModal }
