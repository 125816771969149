import React from 'react'
import { AnalyticType } from '../ts/interfaces/analytics'
import { AnalyticsService } from '../services/Analytics'
import { DateValue } from '../components/fields/Date/DateRangePicker'
import { isValidDateRange, isEqualDateRange } from '../utils/date'
import { ExpensesFilterBy } from '../ts/interfaces/expenses'
import { GetAnalyticsResponse } from '../ts/interfaces/request'

interface UseAnalyticsOutput {
  isLoading: boolean
  data: GetAnalyticsResponse['data']
}
const useAnalytics = ({ type, dateRange, enable, filterBy }: { type: AnalyticType; dateRange: DateValue; enable?: boolean; filterBy: ExpensesFilterBy }): UseAnalyticsOutput => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<GetAnalyticsResponse['data']>([])
  const [dateRangeReference, setDateRangeReference] = React.useState<DateValue | null>(null)
  const [filterByReference, setFilterByReference] = React.useState<ExpensesFilterBy | null>(null)

  React.useEffect(() => {
    if (!enable || !isValidDateRange(dateRange) || (isEqualDateRange(dateRangeReference, dateRange) && filterByReference === filterBy)) return
    ;(async () => {
      setIsLoading(true)
      const responseData = await AnalyticsService().getAnalytics({
        type,
        start_date: dateRange[0],
        end_date: dateRange[1],
        filter_by: filterBy
      })
      setData(responseData)
      setDateRangeReference(dateRange)
      setFilterByReference(filterBy)
      setIsLoading(false)
    })()
  }, [dateRange, filterBy, enable])

  return {
    isLoading,
    data
  }
}

export { useAnalytics }
