import { Select } from 'chakra-react-select'
import { FormHelperText, FormLabel, FormControl, LayoutProps } from '@chakra-ui/react'
import { formColors } from '../../utils/colors'
import { inputWidth, field } from '../../utils/ui'
import React from 'react'

export type Value = string | number | undefined | null

export interface IOption {
  label?: string | null
  value: Value
}

interface ISelectTestProps {
  value?: Value | Array<Value>
  name?: string
  id?: string
  label?: string
  placeholder?: string
  options: IOption[]
  isDisabled?: boolean
  isRequired?: boolean
  isInvalid?: boolean
  helperText?: string
  onChange?: (name: string, value: Value | Value[]) => void
  onBlur?: any
  onFocus?: any
  isMulti?: boolean
  isClearable?: boolean
  customWidth?: LayoutProps['width']
  isSearchable?: boolean
}

const SelectField = ({ name = '', id, label, placeholder = 'Selecciona', options = [], isDisabled = false, helperText = '', value, isInvalid, onChange, onBlur, onFocus, isRequired, isMulti = false, isClearable = true, customWidth, isSearchable = true }: ISelectTestProps) => {
  const valueMemo = React.useMemo(() => {
    return isMulti ? (value as Value[]).map((val) => options.find((option) => option.value === val)) : options.find((el) => el.value === value)
  }, [value, options])
  return (
    <FormControl
      width={customWidth || inputWidth}
      minH={field.inputContainerHeight}
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    >
      <FormLabel
        height='24px'
        opacity={0.5}
      >
        {label} {isRequired ? '*' : ''}
      </FormLabel>
      <Select
        inputId={id}
        isSearchable={isSearchable}
        id={id}
        options={options.map((option) => ({
          value: option.value || null,
          label: option.label || ''
        }))}
        value={valueMemo}
        isInvalid={isInvalid}
        name={name}
        isMulti={isMulti}
        placeholder={placeholder}
        closeMenuOnSelect={isMulti ? false : true}
        isDisabled={isDisabled}
        errorBorderColor={formColors.error.border}
        focusBorderColor={formColors.default.focus.border}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => {
          onChange && onChange(name, isMulti ? (e as IOption[]).map(({ value }) => value) : !e ? null : (e as IOption).value)
        }}
        isClearable={isClearable}
      />
      <FormHelperText height={2}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export { SelectField }
