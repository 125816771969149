import { Badge, Box, BoxProps, Flex, Progress, Skeleton, Text, useBoolean } from '@chakra-ui/react'
import React from 'react'
import { colors } from '../../utils/colors'
import { rounded, fontSize, containerCardStyles } from '../../utils/ui'
import { useAppSelector } from '../../store/hooks'
import { useEffect } from 'react'
import { InvoicesService } from '../../services/Invoices'

interface IServiceUseProgressProps extends BoxProps {}

const ServiceUseProgress = React.memo(({ ...props }: IServiceUseProgressProps) => {
  const invoices = useAppSelector((state) => state.invoices)
  const [isLoading, toggleIsLoading] = useBoolean(false)

  const retrieveData = async () => {
    toggleIsLoading.on()
    await InvoicesService().getInvoicesStatus({ fetch_stats: true })
    toggleIsLoading.off()
  }

  useEffect(() => {
    retrieveData()
  }, [])

  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex
        id='ServiceUseProgress'
        bg={colors.white}
        borderColor={colors.border}
        borderWidth={1}
        rounded={rounded.container}
        padding='1rem'
        direction='column'
        justifyContent='center'
        {...containerCardStyles}
        {...props}
      >
        <Flex
          w='100%'
          flexWrap='wrap'
          flexDir={{
            base: 'row',
            lg: 'row'
          }}
          justifyContent={{
            base: 'space-between',
            lg: 'space-between'
          }}
          alignItems={{
            base: 'start',
            lg: 'center'
          }}
        >
          <Flex
            flexDir='column'
            alignItems='start'
          >
            <Badge
              fontSize={{
                base: '1.6rem',
                md: "'1.6rem'"
              }}
              fontWeight={600}
              colorScheme='purple'
            >
              {invoices.count}
            </Badge>
            <Text
              as='span'
              fontWeight={500}
              fontSize={fontSize.title}
            >
              Tickets enviados
            </Text>
          </Flex>
          <Flex
            flexDir='column'
            alignItems='end'
          >
            <Badge
              fontSize={{
                base: '1.6rem',
                md: "'1.6rem'"
              }}
              fontWeight={600}
              colorScheme='purple'
            >
              {invoices.limit}
            </Badge>
            <Text
              as='span'
              fontWeight={500}
              fontSize={fontSize.title}
            >
              Límite de tickets
            </Text>
          </Flex>
        </Flex>
        <Progress
          colorScheme='blue'
          rounded='0.15rem'
          hasStripe
          size='md'
          value={(!invoices.count ? 0 : invoices.count * 100) / invoices.limit}
          my='1rem'
        />
      </Flex>
    </Skeleton>
  )
})

export { ServiceUseProgress }
