import { Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Center, ModalCloseButton, SimpleGrid, useBoolean } from '@chakra-ui/react'
import React from 'react'
import { DateRangePicker, DateValue } from '../fields/Date/DateRangePicker'
import moment from 'moment'
import { SelectField } from '../fields'
import { UserService } from '../../services/User'
import { useAppSelector } from '../../store/hooks'
import { getTaxInformationName } from '../../ts/interfaces/invoices'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import { ExpensesFilterBy, ExpensesFilterByOptions } from '../../ts/interfaces/expenses'

interface IExpensesFilterProps {
  handleRetrieveData: () => Promise<void>
  dateValue: DateValue
  setDateValue: (value: DateValue) => void
  filterBy: ExpensesFilterBy
  setFilterBy: (value: ExpensesFilterBy) => void
  userId?: number | null
  setUserId: (value?: number | null) => void
  taxInformationId?: string | null
  setTaxInformationId: (value?: string | null) => void
}

const ExpensesFilterComponent = React.forwardRef(({ handleRetrieveData, dateValue, filterBy, setDateValue, setFilterBy, setTaxInformationId, setUserId, taxInformationId, userId }: IExpensesFilterProps, ref) => {
  const taxInformationList = useAppSelector((state) => state.invoices.taxInformation)
  const userList = useAppSelector((state) => state.organization.users)
  const [searchParams, setSearchParams] = useSearchParams()
  React.useEffect(() => {
    UserService({ useLoader: true }).getAllUsers()
  }, [])

  React.useEffect(() => {
    handleRetrieveData()
  }, [dateValue, filterBy, userId, taxInformationId])

  return (
    <Flex
      justifyContent={{
        base: 'center',
        md: 'start'
      }}
      placeItems='center'
      columnGap='1rem'
      flexDir='row'
      flexWrap='wrap'
      width='100%'
      mb='2rem'
    >
      <DateRangePicker
        label='Filtrar por fecha'
        value={dateValue}
        onChange={(value) => {
          searchParams.set('start_date', moment(value[0]).toISOString())
          searchParams.set('end_date', moment(value[1]).toISOString())
          setSearchParams(searchParams)
          setDateValue(value)
        }}
        id='FilterExpenseDatePickerInput'
      />
      <SelectField
        isSearchable={false}
        isClearable={false}
        options={ExpensesFilterByOptions}
        value={filterBy}
        onChange={(_, value) => {
          setFilterBy(value as ExpensesFilterBy)
        }}
        label='Filtrar por fecha de:'
      />
      <SelectField
        isClearable={true}
        id='FilterExpenseTaxInformationSelect'
        label='Filtrar por razón social'
        value={taxInformationId}
        options={taxInformationList.map((taxInformation) => ({
          label: `${getTaxInformationName(taxInformation)} - ${taxInformation.tax_id}`,
          value: taxInformation.tax_information_id
        }))}
        onChange={(_, value) => {
          setTaxInformationId(value as string)
        }}
      />
      <SelectField
        isClearable={true}
        id='FilterExpenseUserSelect'
        label='Filtrar por usuario'
        value={userId}
        options={userList.map((user) => ({
          value: user.id,
          label: user.name || user.email
        }))}
        onChange={(_, value) => {
          setUserId(value as number)
        }}
      />
    </Flex>
  )
})

const ExpensesFilter = React.memo(ExpensesFilterComponent)

export { ExpensesFilter }
