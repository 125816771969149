import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { HighchartsReactProps } from 'highcharts-react-official'
import { Flex, Skeleton, useMediaQuery } from '@chakra-ui/react'
import { colors } from '../../../utils/colors'

interface IData {
  label: string
  value: number
}

interface IBarChartProps {
  title?: string
  serieTitle?: string
  xAxisTitle?: string
  yAxisTitle?: string
  isLoading?: boolean
  data: IData[]
  tooltip?: Highcharts.TooltipOptions | undefined
  contentBoxWidth: number
}

const BarChart = React.memo(({ contentBoxWidth, xAxisTitle, yAxisTitle, title, serieTitle, data, isLoading, tooltip = {} }: IBarChartProps) => {
  const responsives = useMediaQuery(['(min-width: 1900px)', '(min-width: 1366px)', '(min-width: 1024px)', '(min-width: 860px)'])

  const width = React.useMemo(() => (responsives.some((size) => size) ? contentBoxWidth / 2 : contentBoxWidth), [contentBoxWidth, responsives])

  const isScrollable = React.useMemo(() => data.length > 10, [data])

  const scrollablePlotAreaWidth = React.useMemo(() => {
    if (isScrollable) {
      const scalator = data.length > 30 ? 28 : 22
      const additionalWidth = data.length * scalator
      return width + additionalWidth
    }
    return width
  }, [data, width])

  const chartOptions = React.useMemo(
    () =>
      ({
        chart: {
          type: 'column',
          backgroundColor: colors.neutro[3],
          width: width,
          height: 600,
          marginTop: 70,
          marginBottom: 150,
          scrollablePlotArea: {
            minWidth: scrollablePlotAreaWidth, // Adjust minWidth for scrolling
            scrollPositionX: 0
          }
        },
        title: {
          text: title
        },
        tooltip: {
          outside: false
        },
        xAxis: {
          title: {
            text: xAxisTitle
          },
          categories: data.map(({ label }) => label)
        },
        yAxis: {
          title: {
            text: yAxisTitle
          }
        },
        legend: {
          enabled: false
        },
        series: [
          {
            name: serieTitle,
            tooltip: tooltip,
            pointWidth: 30,
            color: colors.tertiary[1],
            data: data.map(({ value }) => value),
            pointPadding: 0,
            groupPadding: 0
          }
        ]
      }) as HighchartsReactProps['options'],
    [width, title, xAxisTitle, yAxisTitle, serieTitle, data, tooltip]
  )

  return (
    <Flex
      width='100%'
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      {isLoading ? (
        <>
          <Skeleton
            h='20px'
            w='100%'
            mb='1rem'
          />

          <Skeleton
            h='300px'
            w='100%'
          />
        </>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </Flex>
  )
})

export { BarChart }
