import React from 'react'
import { Button, Menu, MenuButton, MenuItem, MenuList, Flex, useMediaQuery, Text } from '@chakra-ui/react'
import { Table, PaginationState } from '@tanstack/react-table'
import { IoArrowDown } from 'react-icons/io5'
import { PAGINATION_CONFIG } from './Table'
import { colors } from '../../utils/colors'

interface IPaginatorProps<Data extends object> {
  table: Table<Data>
  pagination: PaginationState
  pageCount: number
  isDisabled?: boolean
}

const getPaginationItems = (currentPage: number, totalPages: number) => {
  if (totalPages <= 7) {
    let pages: (number | string)[] = []
    // If there are 7 or fewer pages, display them all
    for (let i = 0; i < totalPages; i++) {
      pages.push(i)
    }
    return pages
  } else {
    if (currentPage <= 3) {
      // Near the start
      return [0, 1, 2, 3, 4, '...', totalPages - 1]
    } else if (currentPage <= totalPages - 1 && currentPage >= totalPages - 5) {
      return [0, '...', totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1]
    } else {
      return [0, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages - 1]
    }
  }
}

const Paginator = <Data extends object>({ table, pagination, pageCount, isDisabled }: IPaginatorProps<Data>) => {
  const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')

  const pages = React.useMemo(() => getPaginationItems(pagination.pageIndex, pageCount), [pagination, pageCount])

  return (
    <Flex
      width='fit-content'
      alignSelf='end'
      justifyContent='end'
      my='1rem'
      gap='0.7rem'
    >
      <Button
        isDisabled={isDisabled}
        onClick={() => table.firstPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </Button>
      <Button
        isDisabled={isDisabled}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </Button>
      {isLargeScreen
        ? pages.map((page, index) => {
            if (page === '...') {
              return (
                <Text
                  key={`ellipsis-${index}`}
                  alignSelf='center'
                  mx='2'
                >
                  ...
                </Text>
              )
            } else {
              return (
                <Button
                  key={`page-${page}`}
                  bg={pagination.pageIndex === page ? colors.tertiary[3] : 'initial'}
                  onClick={() => {
                    table.setPageIndex(page as number)
                  }}
                >
                  {(page as number) + 1}
                </Button>
              )
            }
          })
        : null}
      <Button
        isDisabled={isDisabled}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>'}
      </Button>
      <Button
        isDisabled={isDisabled}
        onClick={() => table.lastPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </Button>
      <Menu>
        <MenuButton
          isDisabled={isDisabled}
          shadow='base'
          as={Button}
          rightIcon={<IoArrowDown />}
        >
          {pagination.pageSize}
        </MenuButton>
        <MenuList
          onClick={(e) => {
            //@ts-ignore
            table.setPageSize(Number(e.target.value))
          }}
        >
          {PAGINATION_CONFIG.pages.map((page, index) => (
            <MenuItem
              value={page}
              key={`${page}-${index}`}
            >
              {page}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}

export { Paginator }
