import React from 'react'
import { Flex, Text, Badge, Icon, Menu, MenuButton, MenuItem, MenuList, Tag, Button, useMediaQuery, useToast, useBoolean, Tooltip } from '@chakra-ui/react'
import { DataTable } from '../../fragments/Table'
import { colors, receiptStatusColor } from '../../../utils/colors'
import { FaCalendar, FaEye } from 'react-icons/fa'
import { SendInvoiceButton } from '../SendInvoiceButton'
import { useAppSelector } from '../../../store/hooks'
import { selectSubscriptionActive } from '../../../store/subscription/slice'
import { ReceiptStatus, Receipt } from '../../../ts/interfaces/invoices'
import { formatCurrencyAmount } from '../../../utils/ui'
import { MdDownload, MdFolderZip, MdPictureAsPdf } from 'react-icons/md'
import moment from 'moment'
import { routerObj } from '../../routing/Router'
import { ExpensesService } from '../../../services/Expenses'
import { DownloadExpenseType } from '../../../ts/interfaces/expenses'
import { FaPencil } from 'react-icons/fa6'
import { createColumnHelper } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import { UpdateExpenseModal } from '../UpdateExpenseModal'
interface IExpenseTableProps {
  isLoading: boolean
  handleRetrieveData: () => Promise<void>
}
interface TableData extends Partial<Receipt> {
  actions?: string
  tax_identification?: string
  user_name?: string
  status: keyof typeof ReceiptStatus
  category: string
  expense_id: number
  date: Date
  created_at: Date
}

const receiptTableColumnHelper = createColumnHelper<TableData>()

const ExpensesTable = React.memo(({ isLoading, handleRetrieveData }: IExpenseTableProps) => {
  const subscriptionIsActive = useAppSelector(selectSubscriptionActive)
  const toast = useToast()
  const expenses = useAppSelector((state) => state.expenses.expenses)
  const navigate = useNavigate()
  const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')

  const [updateExpenseModalIsOpen, toggleUpdateExpenseModalIsOpen] = useBoolean(false)
  const [updateExpenseId, setUpdateExpenseId] = React.useState<number | null>(null)

  const tableData = React.useMemo(() => {
    return expenses
      .reduce((acc: TableData[], item) => {
        let result: TableData = {
          category: item.expense.category,
          user_name: item?.user?.name || item?.user?.email,
          amount: item.expense.amount,
          status: 'not_receipt',
          expense_id: item.expense.id,
          store: item.expense.name,
          date: item.expense.date,
          created_at: item.expense.created_at
        }

        if (item.tax_information && item.receipt) {
          const { business_name, first_name, surname } = item.tax_information

          const tax_identification = item.tax_information.tax_id.length === 13 ? `${first_name} ${surname}` : business_name

          result = {
            ...result,
            receipt_id: item.receipt.data.receipt_id,
            invoice_xml_url: item.receipt.data.invoice_xml_url,
            invoice_pdf_url: item.receipt.data.invoice_pdf_url,
            status: item.receipt.status,
            tax_identification
          }
        }

        acc.push(result)

        return acc
      }, [])
      .sort((a, b) => (moment(a.date) > moment(b.date) ? -1 : 1))
  }, [expenses])

  const receiptColumns = React.useMemo(
    () => [
      receiptTableColumnHelper.accessor('status', {
        cell: (info) => (
          //@ts-ignore
          <Badge bg={receiptStatusColor[info.getValue()]}>
            {/* @ts-ignore */}
            {ReceiptStatus[info.getValue()]}
          </Badge>
        ),
        header: 'Estatus Factura'
      }),
      receiptTableColumnHelper.accessor('category', {
        cell: (info) => <Badge>{info.getValue()}</Badge>,
        header: 'Categoría'
      }),
      receiptTableColumnHelper.accessor('tax_identification', {
        cell: (info) => info.getValue(),
        header: 'Razón Social'
      }),
      receiptTableColumnHelper.accessor('user_name', {
        cell: (info) => info.getValue(),
        header: 'Usuario'
      }),
      receiptTableColumnHelper.accessor('store', {
        cell: (info) => (
          <Text
            textTransform='uppercase'
            fontWeight={700}
            fontSize={{ base: '0.7rem', lg: '.9rem' }}
          >
            {info.getValue()}
          </Text>
        ),
        header: 'Comercio'
      }),
      receiptTableColumnHelper.accessor('amount', {
        cell: (info) => (
          <Tag
            fontSize={{
              base: '0.6rem',
              lg: '.9rem'
            }}
            colorScheme='blue'
            fontWeight={700}
          >
            {formatCurrencyAmount(info.getValue())}
          </Tag>
        ),
        header: 'Monto'
      }),
      receiptTableColumnHelper.accessor('date', {
        cell: (info) => (
          <Tag
            fontSize={{
              base: '0.6rem',
              lg: '.9rem'
            }}
            fontWeight={700}
          >
            {moment(info.getValue()).format('DD/MM/YYYY')}
          </Tag>
        ),
        header: 'Fecha del Gasto'
      }),
      receiptTableColumnHelper.accessor('created_at', {
        cell: (info) => (
          <Tag
            fontSize={{
              base: '0.6rem',
              lg: '.9rem'
            }}
            fontWeight={700}
          >
            {moment(info.getValue()).format('DD/MM/YYYY')}
          </Tag>
        ),
        header: 'Fecha de envío'
      }),

      ...(isLargeScreen ? [] : []),
      receiptTableColumnHelper.accessor('actions', {
        cell: (info) => (
          <Flex
            columnGap='.5rem'
            alignItems='center'
          >
            <Menu>
              <MenuButton
                isDisabled={!info.row.original.status || !info.row.original.receipt_id}
                fontSize='.9rem'
                bg={colors.primary[2]}
                _hover={{
                  bg: colors.tertiary[3]
                }}
                as={Button}
              >
                Descargar&nbsp;
                <Icon as={MdDownload} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  isDisabled={!info.row.original.invoice_pdf_url}
                  onClick={async () => {
                    await ExpensesService({ useLoader: true }).downloadExpense(DownloadExpenseType.PDF, info.row.original.expense_id)
                    toast({
                      title: 'Descarga exitosa',
                      description: 'El PDF de la factura se ha descargado correctamente.',
                      status: 'success'
                    })
                  }}
                  justifyContent='space-between'
                >
                  Descargar PDF <Icon as={MdPictureAsPdf} />
                </MenuItem>
                <MenuItem
                  isDisabled={!info.row.original.invoice_xml_url}
                  onClick={async () => {
                    await ExpensesService({ useLoader: true }).downloadExpense(DownloadExpenseType.XML, info.row.original.expense_id)
                    toast({
                      title: 'Descarga exitosa',
                      description: 'El XML de la factura se ha descargado correctamente.',
                      status: 'success'
                    })
                  }}
                  justifyContent='space-between'
                >
                  Descargar XML
                  <Icon as={MdFolderZip} />
                </MenuItem>
              </MenuList>
            </Menu>
            <Tooltip label='Ver detalle'>
              <Button
                id={`expenseDetailsButton-${info.row.index}`}
                fontSize='.9rem'
                bg={colors.tertiary[3]}
                aria-label='details button'
                onClick={() => navigate(`${routerObj.receiptDetails.cleanParamsPath}/${info.row.original.expense_id}`)}
                padding='0.4rem'
                cursor='pointer'
              >
                <FaEye />
              </Button>
            </Tooltip>

            <Tooltip label='Modificar'>
              <Button
                id={`expenseUpdateButton-${info.row.index}`}
                fontSize='.9rem'
                bg={colors.tertiary[3]}
                aria-label='details button'
                onClick={() => {
                  setUpdateExpenseId(info.row.original.expense_id)
                  toggleUpdateExpenseModalIsOpen.on()
                }}
                padding='0.4rem'
                cursor='pointer'
              >
                <FaPencil />
              </Button>
            </Tooltip>
          </Flex>
        ),
        header: ''
      })
    ],
    [tableData, expenses, isLargeScreen]
  )

  return (
    <>
      <DataTable
        isLoading={isLoading}
        data={tableData}
        columns={receiptColumns}
        noDataDisclaimer={
          !subscriptionIsActive ? (
            <Flex
              width='100%'
              height='300px'
              alignItems='center'
              justifyContent='center'
              flexDir='column'
              rowGap='1rem'
            >
              <FaCalendar
                height={80}
                size={80}
                color={colors.text[4]}
              />
              <Text
                fontSize={{
                  base: '1.2rem',
                  md: '1.4rem',
                  lg: '1.6rem'
                }}
              >
                No tienes gastos registrados
              </Text>
              <Text>Factura tu primer gasto desde WhatsApp para que puedas verlo registrado en este Panel de Control.</Text>
              <SendInvoiceButton />
            </Flex>
          ) : (
            <Flex
              width='100%'
              height='300px'
              alignItems='center'
              justifyContent='center'
              flexDir='column'
              rowGap='1rem'
            >
              <FaCalendar
                height={80}
                size={80}
                color={colors.text[4]}
              />
              <Text
                fontSize={{
                  base: '1.2rem',
                  md: '1.4rem',
                  lg: '1.6rem'
                }}
              >
                No hay gastos para este filtro
              </Text>
            </Flex>
          )
        }
      />

      <UpdateExpenseModal
        isOpen={updateExpenseModalIsOpen}
        onClose={toggleUpdateExpenseModalIsOpen.off}
        expenseId={updateExpenseId}
        handleRetrieveData={handleRetrieveData}
      />
    </>
  )
})

export { ExpensesTable }
