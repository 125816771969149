'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { Button, Flex, Icon, SimpleGrid, Text } from '@chakra-ui/react'
import { FaKey, FaPlus, FaTicketAlt } from 'react-icons/fa'
import { rounded, padding, border } from '../utils/ui'
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from '../components/fragments/Table'
import { Title } from '../components/fragments/Title'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { HighchartsReactProps } from 'highcharts-react-official'

type UnitConversion = {
  fromUnit: string
  toUnit: string
  factor: string
}

const data: UnitConversion[] = [
  {
    fromUnit: '111111111',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '233',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '5123',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '5555',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '0858',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: 'sdfdsf',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '111111111',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: 'sdfffff',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '111111111',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '111111111',
    toUnit: 'abc',
    factor: 'editar'
  },
  {
    fromUnit: '111111111',
    toUnit: 'abc',
    factor: 'editar'
  }
]

const columnHelper = createColumnHelper<UnitConversion>()

const columns = [
  columnHelper.accessor('fromUnit', {
    cell: (info) => info.getValue(),
    header: 'Número de telefono'
  }),
  columnHelper.accessor('toUnit', {
    cell: (info) => info.getValue(),
    header: 'Nombre',
    enableHiding: true
  }),
  columnHelper.accessor('factor', {
    cell: (data) => data.getValue(),
    header: 'Acciones'
  })
]

const GroupsDashboardPage = () => {
  return (
    <PageLayout>
      <Flex
        flexDir={{
          base: 'column',
          md: 'row'
        }}
        mb='1rem'
        alignItems='center'
        justifyContent={{
          base: '',
          md: 'space-between'
        }}
      >
        <Title>Usuarios y grupos</Title>
        <Button gap='0.4rem'>
          Agregar nuevo grupo{' '}
          <Icon
            aria-label='agregar grupo'
            as={FaPlus}
          />
        </Button>
      </Flex>
      <SimpleGrid
        columns={{
          base: 1,
          md: 2
        }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={
            {
              chart: {
                type: 'column'
              },
              title: {
                text: 'Total Invoices Over Time'
              },
              xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              },
              yAxis: {
                title: {
                  text: 'Total Invoices'
                }
              },
              series: [
                {
                  name: 'Total Invoices',
                  data: [40, 45, 50, 65, 70, 80, 90, 95, 100, 110, 120, 130]
                }
              ]
            } as HighchartsReactProps['options']
          }
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={
            {
              chart: {
                type: 'column'
              },
              title: {
                text: 'Total Invoices Over Time'
              },
              xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              },
              yAxis: {
                title: {
                  text: 'Total Invoices'
                }
              },
              series: [
                {
                  name: 'Total Invoices',
                  data: [40, 45, 50, 65, 70, 80, 90, 95, 100, 110, 120, 130]
                }
              ]
            } as HighchartsReactProps['options']
          }
        />
      </SimpleGrid>

      <DataTable
        data={data}
        columns={columns}
      />
    </PageLayout>
  )
}

export { GroupsDashboardPage }
