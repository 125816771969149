'use client'
import React from 'react'
import { OnboardingProgress } from '../components/ui/OnboardingProgress'
import { ServiceUseProgress } from '../components/ui/ServiceUseProgress'
import { PageLayout } from '../components/Layout/PageLayout'
import { useAppSelector } from '../store/hooks'
import { Analytics } from '../components/ui/Analytics'
import { Heading } from '../components/ui/Heading'

const HomePage = () => {
  const subscription = useAppSelector((state) => state.subscription)
  const ui = useAppSelector((state) => state.ui)
  return (
    <PageLayout>
      <OnboardingProgress mb='1.5rem' />

      {!ui.isFetching && subscription.payment && subscription.payment?.type !== 'FREE_TRIAL' ? (
        <>
          <Heading>Este Mes</Heading>
          <ServiceUseProgress mb='2rem' />
          <Heading>Resumen</Heading>
          <Analytics />
        </>
      ) : null}
    </PageLayout>
  )
}

export { HomePage }
