import { SystemProps } from '@chakra-ui/react'
import { colors } from './colors'

export const rounded = {
  container: 6,
  input: '0.375rem'
}

export const padding = {
  container: {
    px: {
      base: 0,
      md: 12
    },
    py: {
      base: 4,
      md: 12
    }
  },
  button: {
    px: {
      base: 4,
      md: 6
    },
    py: {
      base: 4,
      md: 6
    }
  }
}

export const inputWidth = {
  base: '300px',
  md: '300px'
}

export const inputHeight = {
  base: '40px',
  md: '40px'
}

export const inputContainerHeight = {
  base: 88
}

export const fontSize = {
  input: '1rem',
  title: {
    base: '1rem',
    md: '1.3rem'
  }
}

export const inputPadding = {
  base: '1rem'
}

export const field = {
  inputWidth: inputWidth,
  inputHeight: inputHeight,
  fontSize: fontSize,
  inputPadding: inputPadding,
  inputContainerHeight,
  labelPadding: {
    base: '0 12px 8px 0'
  }
}

export const border = {
  width: '2px',
  color: colors.tertiary[1],
  containerColor: colors.neutralDark
}

export const formatCurrencyAmount = (number?: number): string => {
  if (!number) return '$0'
  // changes "1000000" into 1,000,000" or "12233.562" to "$12,233.56"
  return `$${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export const containerCardStyles: Partial<SystemProps> = {
  boxSize: 'full',
  bg: '#F9FAFB',
  shadow: 'sm',
  padding: '1rem',
  rounded: 'md',
  borderColor: colors.border,
  borderWidth: 1,
  _focus: {
    borderColor: colors.tertiary[1],
    outlineColor: colors.tertiary[1],
    shadow: 'none'
  }
}
