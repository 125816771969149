import React from 'react'
import { Router } from './components/routing/Router'
import { Provider } from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource-variable/inter'
import { store } from './store'

function App() {
  return (
    <ChakraProvider>
      <Provider store={store}>
        <Router />
      </Provider>
    </ChakraProvider>
  )
}

export default App
