import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../ts/interfaces/interfaces'

interface InitialState {
  users: Array<User>
}

const initialState: InitialState = {
  users: []
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationState: (state, action: PayloadAction<Partial<InitialState>>) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { setOrganizationState } = organizationSlice.actions

export default organizationSlice.reducer
