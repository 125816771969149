import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

const PageLayout = React.memo(({ children, ...props }: BoxProps) => {
  return (
    <Box
      px='1rem'
      pt='1rem'
      pb={{
        base: '4rem',
        md: '3rem'
      }}
      {...props}
    >
      {children}
    </Box>
  )
})

export { PageLayout }
