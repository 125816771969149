'use client'
import React from 'react'
import { PricingBanner } from '../components/ui/PricingBanner'
import { PageLayout } from '../components/Layout/PageLayout'
import { Flex, Text, Heading } from '@chakra-ui/react'

const PricingPage = () => {
  return (
    <PageLayout>
      <Flex
        mb='2rem'
        flexDir='column'
      >
        <Heading
          as='h1'
          fontSize={{
            base: '1.8rem',
            md: '2.5rem'
          }}
          fontWeight={600}
          mb='0.5rem'
        >
          Planes y Precios
        </Heading>
        <Text>Encuentra el plan perfecto para ti y tu negocio. Ofrecemos diversas opciones para satisfacer tus necesidades, desde pequeñas empresas hasta grandes corporaciones.</Text>
      </Flex>
      <PricingBanner />
    </PageLayout>
  )
}

export { PricingPage }
