import React from 'react'
import moment from 'moment'
import { DateValue } from '../components/fields/Date/DateRangePicker'
import { useSearchParams } from 'react-router-dom'
import { ExpensesFilterBy } from '../ts/interfaces/expenses'
import { isoToDate, isValidDateRange, dateToUrlIso } from '../utils/date'
import _ from 'lodash'
// NOTE: this filter applies the logic of get_expenses handler of the zumma-service api

interface IUseUrlParamFilterProps {
  useFilterBy?: boolean
  initialDateRange?: DateValue
  initialFilterBy?: ExpensesFilterBy
}

const getDateInitialValue = (searchParams: URLSearchParams, initialValue?: DateValue): DateValue | undefined => {
  let date1: null | Date = null
  let date2: null | Date = null
  if (searchParams.get('start_date') !== null && searchParams.get('start_date') !== '') date1 = isoToDate(searchParams.get('start_date') as string)
  if (searchParams.get('end_date') !== null && searchParams.get('end_date') !== '')
    date2 = moment(isoToDate(searchParams.get('end_date') as string))
      .endOf('day')
      .toDate()

  const urlParamsDate: DateValue = [date1, date2]

  if (isValidDateRange(urlParamsDate)) return urlParamsDate
  return initialValue
}

const getInitialUserId = (searchParams: URLSearchParams): number | null => {
  const user_id = searchParams.get('user_id')
  if (user_id !== null && user_id !== '') return parseInt(user_id)
  return null
}

const getInitialTaxInformationId = (searchParams: URLSearchParams): string | null => {
  const tax_information_id = searchParams.get('tax_information_id')
  if (tax_information_id !== null && tax_information_id !== '') return tax_information_id
  return null
}

const useUrlParamFilter = ({ useFilterBy = true, initialDateRange, initialFilterBy = ExpensesFilterBy.CREATED_AT }: IUseUrlParamFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterBy, setFilterBy] = React.useState<ExpensesFilterBy>(initialFilterBy)
  const [dateValue, setDateValue] = React.useState<DateValue | null | undefined>(getDateInitialValue(searchParams, initialDateRange))
  const [taxInformationId, setTaxInformationId] = React.useState<string | undefined | null>(getInitialTaxInformationId(searchParams))
  const [userId, setUserId] = React.useState<number | undefined | null>(getInitialUserId(searchParams))

  React.useEffect(() => {
    let date1: null | Date = null
    let date2: null | Date = null
    if (searchParams.get('start_date') !== null && searchParams.get('start_date') !== '') date1 = isoToDate(searchParams.get('start_date') as string)
    if (searchParams.get('end_date') !== null && searchParams.get('end_date') !== '')
      date2 = moment(isoToDate(searchParams.get('end_date') as string))
        .endOf('day')
        .toDate()

    const urlParamsDate: DateValue = [date1, date2]

    if (isValidDateRange(urlParamsDate)) {
      setDateValue(() => urlParamsDate)
    } else if (isValidDateRange(initialDateRange)) {
      searchParams.set('start_date', dateToUrlIso(initialDateRange[0] as Date))
      searchParams.set('end_date', dateToUrlIso(initialDateRange[1] as Date))
      setDateValue(() => initialDateRange)
    } else {
      const newDateRange: DateValue = [moment().startOf('month').toDate(), moment().toDate()]
      setDateValue(() => newDateRange)
      searchParams.set('start_date', dateToUrlIso(newDateRange[0] as Date))
      searchParams.set('end_date', dateToUrlIso(newDateRange[1] as Date))
    }

    if (useFilterBy) searchParams.set('filter_by', filterBy)
    setSearchParams(searchParams)
  }, [])

  return {
    dateValue: dateValue || [null, null],
    filterBy: filterBy,
    setDateValue: (value: DateValue) => {
      if (value[0]) searchParams.set('start_date', dateToUrlIso(value[0]))
      if (value[1]) searchParams.set('end_date', dateToUrlIso(value[1]))
      setSearchParams(searchParams)
      setDateValue(value)
    },
    setFilterBy: (value: ExpensesFilterBy) => {
      searchParams.set('filter_by', value)
      setSearchParams(searchParams)
      setFilterBy(value)
    },
    setUserId: (value?: number | null) => {
      if (value) searchParams.set('user_id', value.toString())
      else searchParams.delete('user_id')
      setSearchParams(searchParams)
      setUserId(value)
    },
    userId,
    taxInformationId,
    setTaxInformationId: (value?: string | null) => {
      if (value) searchParams.set('tax_information_id', value)
      else searchParams.delete('tax_information_id')
      setSearchParams(searchParams)
      setTaxInformationId(value)
    }
  }
}

export { useUrlParamFilter }
