import { RequestHandler, IRequestHandler } from '../lib/RequestHandler'
import { store } from '../store'
import { expenses } from '../store/expenses/slice'
import { GetAllExpensesResponse, UpdateExpenseResponse } from '../ts/interfaces/request'
import { ExpenseStatus, DownloadExpensesBulkType, ExpensesFilterBy, DownloadExpenseType, Expense } from '../ts/interfaces/expenses'
import FileDownload from 'js-file-download'
import moment from 'moment'
import { uiSlice } from '../store/ui/slice'
import { getEndDateString, getStartDateString } from '../utils/date'
import { IExpenseForm } from '../components/ui/UpdateExpenseModal'
import { downloadFileFromSignedUrl } from '../utils/values'

export interface IExpensesFilter {
  start_date: Date
  end_date: Date
  user_id?: number | string | null
  tax_information_id?: string | null
  filter_by?: ExpensesFilterBy
}

const getSearchUrlParamsFilter = ({ end_date, start_date, tax_information_id, user_id, filter_by }: IExpensesFilter): Record<string, string> => {
  const iso_start_date = getStartDateString(start_date)
  const iso_end_date = getEndDateString(end_date)
  let params: Record<string, string> = {
    start_date: iso_start_date,
    end_date: iso_end_date
  }
  if (user_id && user_id !== 0) params['user_id'] = user_id.toString()
  if (tax_information_id) params['tax_information_id'] = tax_information_id
  if (filter_by) params['filter_by'] = filter_by

  return params
}
export const ExpensesService = (props?: IRequestHandler) => ({
  requestHandler: RequestHandler(props || {}),

  async getExpenseStatus(id: string | number): Promise<ExpenseStatus> {
    try {
      const response = await this.requestHandler.get<{ data: ExpenseStatus }>(`expenses/${id}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  },

  async getAllExpenses(params: IExpensesFilter) {
    try {
      store.dispatch(uiSlice.actions.setIsFetching(true))

      const urlSearchParams = new URLSearchParams(getSearchUrlParamsFilter(params))

      const response = await RequestHandler({
        ...props,
        useLoader: false
      }).get<GetAllExpensesResponse>(`expenses?${urlSearchParams}`)
      store.dispatch(expenses.actions.setExpensesList([...response.data.data]))
      store.dispatch(uiSlice.actions.setIsFetching(false))
    } catch (error) {
      throw error
    }
  },

  async downloadExpensesData(download_type: DownloadExpensesBulkType, filter: IExpensesFilter) {
    try {
      const urlSearchParams = new URLSearchParams({
        download_type: download_type,
        ...getSearchUrlParamsFilter(filter)
      })

      const response = await this.requestHandler.post(
        `expenses/download/bulk?${urlSearchParams}`,
        {},
        {
          timeout: 300000, // 5 minutes
          responseType: 'blob'
        }
      )

      const type = download_type == DownloadExpensesBulkType.ZIP_PDF_XML ? 'zip' : download_type.toLowerCase()

      FileDownload(response.data, `gastos_${moment(filter.start_date).format('DD-MM-YYYY')}_${moment(filter.end_date).format('DD-MM-YYYY')}.${type}`)
    } catch (error) {
      throw error
    }
  },

  async downloadExpense(downloadType: DownloadExpenseType, expenseId: number): Promise<void> {
    try {
      const urlSearchParams = new URLSearchParams({
        type: downloadType
      })

      const { data } = await RequestHandler({
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).get<{ data: { url: string; file_name: string } }>(`expenses/download/${expenseId}?${urlSearchParams}`)
      downloadFileFromSignedUrl(data.data.url, data.data.file_name)
    } catch (error) {
      throw error
    }
  },
  async updateExpense(expenseId: Expense['id'], body: IExpenseForm): Promise<UpdateExpenseResponse['data']> {
    try {
      store.dispatch(uiSlice.actions.setIsFetching(true))

      const response = await this.requestHandler.patch<UpdateExpenseResponse>(`expenses/${expenseId}`, body)
      return response.data.data
    } catch (error) {
      throw error
    }
  }
})
