'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { Flex, Stack, useBoolean } from '@chakra-ui/react'
import { Title } from '../components/fragments/Title'
import moment from 'moment'
import { DownloadExpensesModal } from '../components/ui/DownloadExpensesModal'
import { ExpensesFilter } from '../components/ui/ExpensesFilter'
import { ExpensesService } from '../services/Expenses'
import { ExpensesFilterBy } from '../ts/interfaces/expenses'
import { isValidDateRange } from '../utils/date'
import { useUrlParamFilter } from '../hooks/useDateUrlParamFilter'
import { ExpensesTable } from '../components/ui/tables/ExpensesTable'

const ReceiptsDashboardPage = () => {
  const [isLoading, toggleIsLoading] = useBoolean(false)

  const { dateValue, setDateValue, filterBy, setFilterBy, userId, setUserId, taxInformationId, setTaxInformationId } = useUrlParamFilter({ useFilterBy: false, initialFilterBy: ExpensesFilterBy.DATE, initialDateRange: [moment().startOf('month').toDate(), moment().toDate()] })

  const handleRetrieveData = async () => {
    if (isValidDateRange(dateValue)) {
      toggleIsLoading.on()
      await ExpensesService().getAllExpenses({
        start_date: dateValue[0],
        end_date: dateValue[1],
        user_id: userId,
        tax_information_id: taxInformationId
      })
      toggleIsLoading.off()
    }
  }

  React.useEffect(() => {
    handleRetrieveData()
  }, [])

  return (
    <PageLayout>
      <Stack mb='1rem'>
        <Flex
          flexDir={{
            base: 'column',
            md: 'row'
          }}
          mb='1rem'
          alignItems='center'
          justifyContent={{
            base: '',
            md: 'space-between'
          }}
        >
          <Title>Gastos</Title>
          <DownloadExpensesModal />
        </Flex>

        <ExpensesFilter
          setDateValue={setDateValue}
          setTaxInformationId={setTaxInformationId}
          dateValue={dateValue}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          setUserId={setUserId}
          taxInformationId={taxInformationId}
          userId={userId}
          handleRetrieveData={handleRetrieveData}
        />
        <ExpensesTable
          handleRetrieveData={handleRetrieveData}
          isLoading={isLoading}
        />
      </Stack>
    </PageLayout>
  )
}

export { ReceiptsDashboardPage }
