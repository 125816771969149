import React from 'react'
import { InputGroup, FormHelperText, FormLabel, FormControl, ResponsiveValue, Button, Menu, MenuButton, MenuList, MenuItem, IconButton, Input, InputRightElement, LayoutProps } from '@chakra-ui/react'
import { field, inputWidth } from '../../../utils/ui'
import moment from 'moment'
import { FaCalendar, FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'
import ReactDatePicker from 'react-datepicker'
import { IoArrowDown } from 'react-icons/io5'
moment.locale('es')
import { getYear, getMonth } from 'date-fns'
import { colors } from '../../../utils/colors'
import 'react-datepicker/dist/react-datepicker.css'

export type DateValue = [Date | null, Date | null] | [null, null] | [Date, Date] | null[] | Date[]
interface IDateRangePickerProps {
  placeholder?: string
  size?: ResponsiveValue<string> | undefined
  name?: string
  helperText?: string
  label?: string
  id?: string
  isDisabled?: boolean
  value: DateValue
  defaultValue?: string
  onChange: (value: DateValue) => void
  onFocus?: any
  onBlur?: any
  isInvalid?: boolean
  isRequired?: boolean
  isReadOnly?: boolean
  maxDate?: Date
  minDate?: Date
  isClearable?: boolean
  customWidth?: LayoutProps['width']
}

const dateRange = (startYear?: number) => {
  let currentYear = new Date().getFullYear(),
    years = []
  startYear = startYear || 1935
  while (startYear <= currentYear) {
    years.push(startYear++)
  }
  return years
}
const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
const translater = {
  Monday: 'L',
  Tuesday: 'M',
  Wednesday: 'X',
  Thursday: 'J',
  Friday: 'V',
  Saturday: 'S',
  Sunday: 'D'
}

const years = dateRange(1930).reverse()

interface ExampleCustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // Any additional props specific to your custom input
}

const CustomInput = React.forwardRef<HTMLInputElement, ExampleCustomInputProps>((props, ref) => {
  return (
    <InputGroup>
      {/* @ts-ignore */}
      <Input
        cursor='pointer'
        width={inputWidth}
        ref={ref}
        {...props}
      />
      {/* @ts-ignore */}
      {props.value && props.isClearable ? null : (
        <InputRightElement
          cursor='pointer'
          //@ts-ignore
          onClick={props.onFocus}
        >
          <FaCalendar color={colors.text[3]} />
        </InputRightElement>
      )}
    </InputGroup>
  )
})

const DateRangePicker = React.memo(({ placeholder, name, helperText, label, id, isDisabled, onChange, isInvalid = false, value, onFocus, onBlur, isReadOnly, isRequired, minDate, maxDate = new Date(), isClearable = false, customWidth }: IDateRangePickerProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  return (
    <FormControl
      width={customWidth || inputWidth}
      zIndex='sticky'
      minH={field.inputContainerHeight}
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    >
      <FormLabel
        height='24px'
        opacity={0.5}
      >
        {label} {isRequired ? '*' : ''}
      </FormLabel>
      <InputGroup
        zIndex='sticky'
        width='100%'
        flexGrow={1}
      >
        <ReactDatePicker
          strictParsing={true}
          customInput={<CustomInput ref={inputRef} />}
          selectsRange={true}
          dateFormat='dd/MM/yyyy'
          startDate={value[0] || undefined}
          endDate={value[1] || undefined}
          maxTime={maxDate}
          maxDate={maxDate}
          isClearable={isClearable}
          showPopperArrow={null}
          disabled={isDisabled}
          clearButtonClassName={'date-field__light-clear-button'}
          showYearDropdown
          dateFormatCalendar='MMMM'
          yearDropdownItemNumber={90}
          scrollableYearDropdown
          onChangeRaw={(e) => {
            //@ts-ignore
            if (e?.target?.value) {
              //@ts-ignore
              const value = e.target.value
              const dates = value.split(' - ')

              if (dates.length > 1) {
                const startDate = dates[0].trim().split('/')
                const endDate = dates[1].trim().split('/')
                const formattedStartDate = `${startDate[1]}/${startDate[0]}/${startDate[2]}`
                const formattedEndDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`
                if (moment(formattedStartDate).isValid() && moment(formattedEndDate).isValid()) {
                  onChange([moment(formattedStartDate).toDate(), moment(formattedEndDate).toDate()])
                }
                return
              }
              if (dates[0]) {
                const startDate = dates[0].trim().split('/')
                const formattedStartDate = `${startDate[1]}/${startDate[0]}/${startDate[2]}`
                if (moment(formattedStartDate).isValid()) {
                  onChange([moment(formattedStartDate).toDate(), null])
                }
                return
              }
            }
          }}
          onChange={(date) => {
            onChange && onChange(date)
          }}
          formatWeekDay={(dayName) => {
            return translater[dayName as keyof typeof translater]
          }}
          renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled }) => (
            <div className='flex items-center justify-between w-full px-6'>
              <IconButton
                aria-label='previous month'
                icon={<FaLongArrowAltLeft />}
                onClick={(e) => {
                  e.preventDefault()
                  decreaseMonth()
                }}
                disabled={prevMonthButtonDisabled}
              />

              <Menu>
                <MenuButton
                  shadow='base'
                  as={Button}
                  fontSize={{
                    base: 'sm'
                  }}
                  padding={1}
                  rightIcon={<IoArrowDown />}
                >
                  {getYear(date)}
                </MenuButton>
                <MenuList
                  maxH={40}
                  overflowY='scroll'
                  //@ts-ignore
                  onClick={(e) => changeYear(e.target.value)}
                >
                  {years.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  shadow='base'
                  as={Button}
                  fontSize={{
                    base: 'sm'
                  }}
                  padding={1}
                  rightIcon={<IoArrowDown />}
                >
                  {months[getMonth(date)]}
                </MenuButton>
                <MenuList
                  maxH={40}
                  overflowY='scroll'
                  onClick={(e) =>
                    //@ts-ignore
                    changeMonth(months.indexOf(e.target.value))
                  }
                >
                  {months.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              <IconButton
                aria-label='previous month'
                icon={<FaLongArrowAltRight />}
                onClick={(e) => {
                  e.preventDefault()
                  increaseMonth()
                }}
                disabled={prevMonthButtonDisabled}
              />
            </div>
          )}
        />
      </InputGroup>
      <FormHelperText height={2}>{helperText}</FormHelperText>
    </FormControl>
  )
})

export { DateRangePicker }
