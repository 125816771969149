'use client'
import React from 'react'
import { Flex, useToast } from '@chakra-ui/react'
import { TaxInformationForm } from '../components/ui/TaxInformationForm'
import { Title } from '../components/fragments/Title'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { IconButton } from '../components/fragments/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { PageLayout } from '../components/Layout/PageLayout'
import { InvoicesService } from '../services/Invoices'
import { Flatten } from '../ts/interfaces/interfaces'
import { Buffer } from 'buffer'
import { TaxInformation } from '../ts/interfaces/invoices'

const UpdateTaxInformationPage = () => {
  const [taxInformationData, setTaxInformationData] = React.useState<Omit<Flatten<TaxInformation>, 'created_at' | 'updated_at' | 'country'> | null>()

  const taxInformationId = useParams().id
  const decodedTaxIformationId = React.useMemo(() => Buffer.from(taxInformationId || '', 'base64').toString('ascii'), [taxInformationId])

  const navigate = useNavigate()
  const toast = useToast()

  const init = React.useCallback(async () => {
    if (!taxInformationId) return navigate(-1)

    try {
      const data = await InvoicesService().getTaxInformationById(parseInt(decodedTaxIformationId))
      delete data.created_at
      delete data.updated_at

      const { address, ...rest } = data

      setTaxInformationData(() => ({
        ...rest,
        ...address,
        id: rest.id
      }))
    } catch (error) {
      navigate(-1)
      toast({
        title: 'Hubo un problema.',
        status: 'error',
        duration: 8000,
        isClosable: true
      })
    }
  }, [taxInformationId, InvoicesService])

  React.useEffect(() => {
    init()
  }, [taxInformationId])

  return (
    <PageLayout>
      <Flex
        direction='column'
        alignItems='center'
      >
        <Flex
          mt='4rem'
          mb='2rem'
          px='2rem'
          gap='1rem'
          justifyContent='center'
          alignItems='center'
        >
          <IconButton
            onClick={() => navigate(-1)}
            aria-label='go back button'
            icon={<FaArrowAltCircleLeft />}
          />
          <Title>Actualiza tu Información Fiscal</Title>
        </Flex>
        {taxInformationData ? <TaxInformationForm initialForm={taxInformationData} /> : null}
      </Flex>
    </PageLayout>
  )
}

export { UpdateTaxInformationPage }
