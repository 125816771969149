import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ExpenseStatus } from '../../ts/interfaces/expenses'

interface State {
  expenses: ExpenseStatus[]
}

export const initialState: State = {
  expenses: []
}

export const expenses = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setExpensesList: (state, action: PayloadAction<Array<ExpenseStatus>>) => {
      return { ...state, expenses: action.payload }
    },
    clearState: () => {
      return initialState
    }
  }
})

export const { clearState, setExpensesList } = expenses.actions

export default expenses.reducer
