import React from 'react'
import { Box, Button, Flex, FlexProps, Icon, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { colors } from '../../utils/colors'
import { rounded } from '../../utils/ui'
import { FaWhatsapp } from 'react-icons/fa'
import { useAppSelector } from '../../store/hooks'
import QRCode from 'react-qr-code'

interface ISendInvoiceButton extends FlexProps {
  isDisabled?: boolean
  displayQRCode?: boolean
  label?: string
}

const SendInvoiceButton = React.memo(({ isDisabled = false, displayQRCode = false, label = 'Ir a facturar en WhatsApp', ...props }: ISendInvoiceButton) => {
  const user = useAppSelector((state) => state.user)
  const [isMidScreen] = useMediaQuery('(min-width: 720px)')

  const qrModal = useDisclosure()

  const url = React.useMemo(() => `whatsapp://send?phone=${process.env.REACT_APP_ZUMMI_WHATSAPP_PHONE_NUMBER}&text=${encodeURIComponent(`¡Hola Zummi! Quiero facturar un ticket 🧾`)}`, [user])

  return (
    <Flex
      id='SendInvoiceButton'
      flexDir='column'
      alignItems={{
        base: displayQRCode ? 'center' : 'initial'
      }}
      my={displayQRCode ? '2rem' : 'initial'}
      gap={displayQRCode ? '2rem' : 'initial'}
      {...props}
    >
      <Flex
        display={{
          base: displayQRCode ? 'flex' : 'none'
        }}
        flexDir='column'
        alignItems='center'
        gap='1rem'
      >
        <Text>Escanea el QR para ir a WhatsApp desde tu celular</Text>
        <QRCode
          size={150}
          value={url}
        />
      </Flex>
      <Link
        onClick={(e) => {
          if (isMidScreen && !displayQRCode) {
            e.preventDefault()
            qrModal.onOpen()
          }
        }}
        rounded={rounded.container}
        py={'.2rem'}
        px='1rem'
        bg={colors.whatsapp.light}
        as='a'
        href={url}
        textColor={colors.white}
        transition='.2s ease'
        boxShadow='sm'
        _hover={{
          boxShadow: 'xl',
          textDecoration: 'underline'
        }}
      >
        <Text
          fontWeight={600}
          fontSize={{
            base: '1.1rem'
          }}
          textAlign='center'
        >
          {label}&nbsp;
          <Icon
            ml='.6rem'
            fontWeight={700}
            fontSize='2rem'
            as={FaWhatsapp}
          />
        </Text>
      </Link>

      <Modal
        isOpen={qrModal.isOpen}
        onClose={qrModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader px='2rem'>Escanea el QR para ir a WhatsApp desde tu celular</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              flexDir='column'
              alignItems='center'
              justify='center'
              gap='1rem'
            >
              <Text></Text>
              <QRCode
                size={200}
                value={url}
              />
              <Link
                rounded={rounded.container}
                py={'.2rem'}
                px='1rem'
                bg={colors.whatsapp.light}
                as='a'
                href={url}
                textColor={colors.white}
                transition='.2s ease'
                boxShadow='sm'
                _hover={{
                  boxShadow: 'xl',
                  textDecoration: 'underline'
                }}
                textAlign='center'
                justifyContent='center'
              >
                <Text
                  fontWeight={600}
                  fontSize={{
                    base: '1.1rem'
                  }}
                  textAlign='center'
                  justifyContent='center'
                  w='100%'
                >
                  Ir a WhatsApp Web&nbsp;
                  <Icon
                    ml='.6rem'
                    fontWeight={700}
                    fontSize='2rem'
                    as={FaWhatsapp}
                  />
                </Text>
              </Link>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={qrModal.onClose}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
})

export { SendInvoiceButton }
