import React from 'react'
import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react'

interface IHeadingProps extends HeadingProps {}
const Heading = React.memo(({ children, ...props }: IHeadingProps) => {
  return (
    <ChakraHeading
      as='h2'
      fontSize={{
        base: '1.8rem',
        md: '2.5rem'
      }}
      fontWeight={600}
      mb='1rem'
      {...props}
    >
      {children}
    </ChakraHeading>
  )
})

export { Heading }
