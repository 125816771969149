import moment from 'moment'
import { DateValue } from '../components/fields/Date/DateRangePicker'

const isValidDateRange = (dateRange?: DateValue | null | Array<Date | null>): dateRange is [Date, Date] => {
  if (!dateRange) return false
  const copy = [...dateRange]
  if (copy[0] && copy[1] && moment(copy[0]).isValid() && moment(copy[1]).isValid()) {
    return true
  }

  return false
}
const isEqualDateRange = (dateRangeA?: DateValue | null | Array<Date | null>, dateRangeB?: DateValue | null | Array<Date | null>): boolean => {
  if (!isValidDateRange(dateRangeA) || !isValidDateRange(dateRangeB)) return false
  const startRangeSimilar = moment(dateRangeA[0]).format('YYYY-MM-DD') === moment(dateRangeB[0]).format('YYYY-MM-DD')
  const endRangeSimilar = moment(dateRangeA[1]).format('YYYY-MM-DD') === moment(dateRangeB[1]).format('YYYY-MM-DD')

  return startRangeSimilar && endRangeSimilar
}

function getStartDateString(dateValue: Date): string {
  const clone = moment(dateValue).clone().toDate()
  const new_ = toUTCDate(clone).toISOString()
  return new_
}

function getEndDateString(dateValue: Date): string {
  const clone = moment(dateValue).clone().endOf('day').toDate()
  return clone.toISOString() // Remove time portion
}

function removeTimeOffset(isoString: string): Date {
  // Parse the ISO string into a Date object
  const date = moment(isoString).toDate()

  // Get the UTC components of the date
  const utcYear = date.getUTCFullYear()
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are 0-based
  const utcDay = String(date.getUTCDate()).padStart(2, '0')
  const utcHours = String(date.getUTCHours()).padStart(2, '0')
  const utcMinutes = String(date.getUTCMinutes()).padStart(2, '0')
  const utcSeconds = String(date.getUTCSeconds()).padStart(2, '0')

  // Construct a new ISO string in UTC without timezone offset
  const utcIsoString = `${utcYear}-${utcMonth}-${utcDay}T${utcHours}:${utcMinutes}:${utcSeconds}`

  return moment(utcIsoString).toDate()
}

export function dateToUrlIso(date: Date): string {
  return toUTCDate(date).toISOString()
}

/**
 * Converts a given Date object to a UTC Date object.
 * @param {Date} date - The Date object to convert.
 * @returns {Date} - A Date object representing the same date and time in UTC.
 */
export function toUTCDate(date: Date) {
  // Create a new Date object using the UTC components of the provided date
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()))
}

export const isoToDate = (payload: string): Date => {
  const payloadSplit = payload.split('-') // Returns an array of substrings, one substring before each '-' character
  const payloadYear = parseInt(payloadSplit[0])
  const payloadIndexedMonth = parseInt(payloadSplit[1]) - 1
  const payloadDate = parseInt(payloadSplit[2].split('T')[0])

  // Use the payload's year, month, and day to create a new Date object, set to midnight at local time
  return new Date(payloadYear, payloadIndexedMonth, payloadDate, 0, 0, 0, 0)
}

export { isValidDateRange, isEqualDateRange, getEndDateString, getStartDateString, removeTimeOffset }
