import { configureStore } from '@reduxjs/toolkit'
import user from './user/slice'
import ui from './ui/slice'
import subscription from './subscription/slice'
import invoices from './invoices/slice'
import organization from './organization/slice'
import expenses from './expenses/slice'

export const store = configureStore({
  reducer: {
    user,
    invoices,
    ui,
    subscription,
    organization,
    expenses
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
