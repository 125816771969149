import React, { HTMLAttributeAnchorTarget } from 'react'
import { Text } from '@chakra-ui/react'
import { Link as RLink } from 'react-router-dom'
import { colors } from '../../utils/colors'

interface ILinkProps {
  href: string
  children: any
  target?: HTMLAttributeAnchorTarget
}
const Link = React.memo(({ href, children, target = '_blank' }: ILinkProps) => {
  return (
    <Text
      as='span'
      display='inline'
      mt='1rem'
      gap='.55rem'
      fontWeight={600}
      textColor={colors.tertiary[1]}
    >
      <RLink
        target={target}
        to={href}
      >
        {children}
      </RLink>
    </Text>
  )
})

export { Link }
