'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { Badge, Button, Flex, FlexProps, Icon, SimpleGrid, Tag, Text, useToast } from '@chakra-ui/react'
import { CFDI, PaymentMethod, ReceiptStatus, TaxRegimes, TRANSLATED_ERROR_CODE } from '../ts/interfaces/invoices'
import { formatCurrencyAmount, rounded, border, containerCardStyles } from '../utils/ui'
import moment from 'moment'
import { Title } from '../components/fragments/Title'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { IconButton } from '../components/fragments/Button'
import { colors, receiptStatusColor } from '../utils/colors'
import { MdDownload } from 'react-icons/md'
import { ExpensesService } from '../services/Expenses'
import { ExpenseStatus, DownloadExpenseType } from '../ts/interfaces/expenses'

const ReceiptDetails = () => {
  const [details, setDetails] = React.useState<ExpenseStatus | null>()
  const id = useParams().id as string
  const navigate = useNavigate()
  const toast = useToast()

  const init = React.useCallback(async () => {
    try {
      const response = await ExpensesService().getExpenseStatus(id)
      setDetails(response)
    } catch (error) {
      navigate(-1)
    }
  }, [id])

  React.useEffect(() => {
    init()
  }, [])

  const Property = ({ children, title }: { children: JSX.Element | string | number | undefined | Array<JSX.Element | string | number | undefined>; title: string }) => (
    <Flex
      flexDir='column'
      alignItems='start'
    >
      <Title
        fontSize='1.3rem'
        as='h4'
      >
        {title}:
      </Title>
      <Text>{children}</Text>
    </Flex>
  )

  const Section = ({ children, title, ...props }: FlexProps & { children: JSX.Element | JSX.Element[]; title: string }) => (
    <Flex
      maxH='fit-content'
      justifyContent={{
        base: 'space-between',
        md: 'space-between'
      }}
      columnGap={{
        base: '2rem'
      }}
      rowGap={{
        base: '1.5rem'
      }}
      alignItems={{
        base: 'start'
      }}
      borderColor={colors.neutro[1]}
      borderWidth={2}
      rounded={rounded.container}
      // bg='pink'
      flexWrap='wrap'
      padding={{
        base: '1rem',
        md: '1.5rem'
      }}
      mb='1rem'
      {...props}
    >
      <Title
        textAlign='center'
        w='100%'
      >
        {title}
      </Title>
      {children}
    </Flex>
  )

  const userSection = (
    <Section title='Usuario'>
      <Property title='Nombre'>{details?.user?.name || '-'}</Property>
      <Property title='Email'>{details?.user?.email}</Property>
      <Property title='Número de telefono'>{details?.user?.phone_numbers.join(',')}</Property>
    </Section>
  )

  if (!details) return null
  else if (!details.receipt)
    return (
      <PageLayout>
        <Flex
          flexDir={{
            base: 'row'
          }}
          mb='1rem'
          alignItems='start'
          gap='1rem'
          justifyContent={{
            base: ''
          }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            aria-label='go back button'
            icon={<FaArrowAltCircleLeft />}
          />
          <Title>Detalles de gasto</Title>
        </Flex>

        <SimpleGrid
          columns={{
            base: 1,
            lg: 2
          }}
          columnGap='1rem'
        >
          {userSection}
          <Section title='Información de gasto'>
            <Property title='Monto'>
              <Tag
                fontSize={{
                  base: '1rem',
                  lg: '.9rem'
                }}
                colorScheme='blue'
                fontWeight={700}
              >
                {formatCurrencyAmount(details.expense.amount)}
              </Tag>
            </Property>

            <Property title='Fecha'>
              <Badge>{moment(details.expense.date).format('DD/MM/YYYY')}</Badge>
            </Property>

            <Property title='Categoría'>
              <Tag
                fontSize={{
                  base: '1rem',
                  lg: '.9rem'
                }}
                colorScheme='purple'
                fontWeight={700}
              >
                {details.expense.category}
              </Tag>
            </Property>

            <Property title='Comercio'>{details.expense.name}</Property>
          </Section>
        </SimpleGrid>
        <SimpleGrid
          display={details.expense.image ? 'grid' : 'none'}
          columns={{
            base: 1,
            lg: 2
          }}
          columnGap='1rem'
        >
          <Flex
            {...containerCardStyles}
            justifyContent='center'
            alignItems='center'
            width={{
              base: '100%s'
            }}
          >
            <img
              src={details.expense.image}
              alt='details image'
            />
          </Flex>
        </SimpleGrid>
      </PageLayout>
    )

  return (
    <PageLayout>
      <Flex
        flexDir={{
          base: 'row'
        }}
        mb='1rem'
        alignItems='start'
        gap='1rem'
        justifyContent={{
          base: ''
        }}
      >
        <IconButton
          onClick={() => navigate(-1)}
          aria-label='go back button'
          icon={<FaArrowAltCircleLeft />}
        />
        <Title>Información de Gasto</Title>
      </Flex>

      <SimpleGrid
        columns={{
          base: 1,
          lg: 2
        }}
        columnGap='1rem'
      >
        {userSection}
        <Section title='Información fiscal'>
          <Property title='RFC'>{details?.tax_information.tax_id}</Property>
          <Property title='Razón Social'>{details?.tax_information.business_name ? details.tax_information.business_name : `${details.tax_information.first_name} ${details.tax_information.middle_name} ${details.tax_information.last_name} ${details.tax_information.surname}`}</Property>

          {/* @ts-ignore */}
          <Property title='Régimen fiscal'>{TaxRegimes[details?.tax_information.tax_regime.toString()]}</Property>
        </Section>
      </SimpleGrid>

      <SimpleGrid
        columns={{
          base: 1,
          lg: 2
        }}
        columnGap='1rem'
        rowGap='1rem'
        placeItems='start'
      >
        <Flex
          justifyContent='center'
          alignItems='center'
          width={{
            base: '100%s'
          }}
        >
          <img
            src={details.expense.image}
            alt='details image'
          />
        </Flex>
        <Section title='Facturación'>
          <Property title='Estatus'>
            <Badge
              fontSize={{
                base: '1rem'
              }}
              bg={receiptStatusColor[details.receipt.status]}
            >
              {/* @ts-ignore */}
              {ReceiptStatus[details?.receipt.status]}
            </Badge>
          </Property>

          <Property title='Monto'>
            <Tag
              fontSize={{
                base: '1rem',
                lg: '.9rem'
              }}
              colorScheme='blue'
              fontWeight={700}
            >
              {formatCurrencyAmount(details?.expense.amount)}
            </Tag>
          </Property>
          <Property title='Comercio'>{details.expense.name}</Property>

          <Property title='Categoría'>{details.expense.category}</Property>

          {/* @ts-ignore */}
          <Property title='CFDI'>{CFDI[details?.receipt?.data.cfdi]}</Property>

          {/* @ts-ignore */}
          <Property title='Método de pago'>{PaymentMethod[details?.receipt.data.payment_method]}</Property>

          <Property title='Fecha de envío'>{moment(details.expense.created_at).format('DD/MM/YYYY')}</Property>
          <Property title='Fecha de recibo'>{moment(details.expense.date).format('DD/MM/YYYY')}</Property>

          <Property title='Descargar PDF'>
            <Button
              isDisabled={!details.receipt.data.invoice_pdf_url}
              bg={colors.primary[2]}
              onClick={async () => {
                await ExpensesService({ useLoader: true }).downloadExpense(DownloadExpenseType.PDF, details.expense.id)
                toast({
                  title: 'Descarga exitosa',
                  description: 'El PDF de la factura se ha descargado correctamente.',
                  status: 'success'
                })
              }}
            >
              Descargar PDF <Icon as={MdDownload} />
            </Button>
          </Property>
          <Property title='Descargar XML'>
            {' '}
            <Button
              isDisabled={!details.receipt.data.invoice_xml_url}
              bg={colors.primary[2]}
              onClick={async () => {
                await ExpensesService({ useLoader: true }).downloadExpense(DownloadExpenseType.XML, details.expense.id)
                toast({
                  title: 'Descarga exitosa',
                  description: 'El XML de la factura se ha descargado correctamente.',
                  status: 'success'
                })
              }}
            >
              Descargar XML <Icon as={MdDownload} />
            </Button>
          </Property>
          {details.receipt.error?.code ? (
            <Flex
              borderWidth={border.width}
              borderColor={colors.error[1]}
              rounded={rounded.container}
              p='1rem'
              flexDir='column'
            >
              <Text fontWeight={600}>Error:</Text>
              <Text>{TRANSLATED_ERROR_CODE[details.receipt.error.code]}</Text>
            </Flex>
          ) : (
            <></>
          )}
        </Section>
      </SimpleGrid>
    </PageLayout>
  )
}

export { ReceiptDetails }
