import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

interface TitleProps extends TextProps {}

const Title = React.memo(({ children, ...props }: TitleProps) => {
  return (
    <Text
      as='h2'
      fontSize={{
        base: '1.5rem',
        md: '1.9rem'
      }}
      textAlign={{
        base: 'center',
        md: 'start'
      }}
      fontWeight={600}
      mb='0.5rem'
      {...props}
    >
      {children}
    </Text>
  )
})

export { Title }
