import { useFormik, FormikConfig, FormikValues } from 'formik'
import * as React from 'react'

const useForm = <Values extends FormikValues = FormikValues>(
  props: FormikConfig<Values>
): {
  handleFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSetTouchedForm?: any
  handleSelectChange?: (name: string, value: string | number | undefined | null | Array<string | number | undefined | null>) => void
} & ReturnType<typeof useFormik<Values>> => {
  const form = useFormik(props)

  return {
    ...form,
    handleSelectChange: (name, value) => {
      form.setFieldValue(name, value).then((re) => {
        form.validateField(name)
      })
    },
    handleFocus: (event: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldTouched(event.target.name, true)
    },
    handleSetTouchedForm: () => {
      for (const key in Object.keys(form.initialValues)) {
        form.setFieldTouched(key, true)
      }
    }
  }
}

export { useForm }
