import { ExpenseStatus, DownloadExpensesBulkType, ExpensesFilterBy, Expense } from './expenses'
import { Product, User } from './interfaces'
import { TaxInformation, InvoiceStatus } from './invoices'
import { AnalyticType } from './analytics'

export type ContributionsMonthByMonthResponse = Array<{
  month: number
  year: number
  total: number
}>

export type CardsIncomesResponse = Array<{
  totalIncome: number
  card: string
}>

export type CardsExpensesResponse = Array<{
  totalSpent: number
  card: string
}>

export type GetUserDetailsResponse = User

export type ExpensesVsCategoriesResponse = Array<{
  totalSpent: number
  expenseCategory: string
}>

export type PaymentBreakdownResponse = Array<{
  totalPercentage: number
  paymentMethodName: string
  totalSpent: number
}>

export type BudgetVsExpensesResponse = Array<{
  totalSpent: number
  budget: {
    name: string
    amount: number
  }
}>

export type TotalBalanceResponse = {
  total: number
}

export type BalanceByDateResponse = Array<{
  date: string
  total: number
}>

export interface SignUpBody {
  email: string
  password: string
  phone_number: string
}

export interface SignInBody extends Omit<SignUpBody, 'phone_number'> {}

export type GetTaxInformationResponse = TaxInformation[]

export interface CreateTaxInformationBody extends Omit<TaxInformation, 'id' | 'tax_certificate' | 'tax_information_id' | 'created_at' | 'updated_at' | 'deleted_at'> {
  tax_certificate?: string | null
  email: string
}
export interface CreateTaxInformationResponse {
  data: TaxInformation
}

export interface UpdateTaxInformationBody extends Omit<TaxInformation, 'tax_certificate' | 'created_at' | 'updated_at' | 'deleted_at' | 'tax_information_id'> {}
export interface UpdateTaxInformationResponse {
  data: TaxInformation
}
export interface DeleteTaxInformationResponse {
  data: TaxInformation
}
export interface EnableTaxInformationResponse {
  data: TaxInformation
}

export interface GetInvoicesStatusResponse {
  data: {
    active: boolean
    count: number
    limit: number
    total: number
  }
}

export interface GetInvoicesActiveResponse {
  data: {
    active: boolean
  }
}

export interface GetTaxInformationListResponse {
  data: TaxInformation[]
}

export interface GetTaxInformationByIdResponse {
  data: TaxInformation
}

export interface ResetPasswordBody {
  password: string
  access_token: string
  refresh_token: string
}

export enum PaymentType {
  BANK_TRANSFER = 'BANK_TRANSFER',
  STRIPE = 'STRIPE',
  FREE_TRIAL = 'FREE_TRIAL'
}
export interface Payment {
  amount: number
  type: PaymentType
  transfer_id?: string
  stripe_subscription_id?: string
}

export interface GetSubscriptionStatusResponse {
  data: {
    products: Array<Product>
    payment?: Payment
  }
}

export interface GetAllReceiptsResponse {
  data: Array<InvoiceStatus>
}

export interface GetReceiptResponse extends InvoiceStatus {}

export interface GetAllUsersResponse {
  data: User[]
}

export interface GetUserResponse {
  data: User
}

export interface CreateUserResponse {
  data: User
}

export interface UpdateUserResponse {
  data: User
}

export interface GetAllExpensesResponse {
  data: Array<ExpenseStatus>
}

export interface DownloadExpensesDataBody {
  download_type: DownloadExpensesBulkType
  start_date: Date
  end_date: Date
}

export interface DeleteUserResponse {
  data: User
}

export interface EnableUserResponse {
  data: User
}

export interface GetAnalyticsBody {
  type: AnalyticType
  start_date: Date
  end_date: Date
  filter_by: ExpensesFilterBy
}

export interface GetAnalyticsResponse {
  data: { label: string; value: number }[]
}

export interface GetStatsResponse {
  data: {
    total_spent: number
    total_receipts: number
  }
}

export interface GetAllUsersParams {
  filter_by?: string | undefined | null
  setStateResult?: boolean
}

export interface UpdateExpenseResponse {
  data: Expense
}
