import { Box, Flex, Link, List, ListIcon, ListItem, Text, Icon, Grid, Alert, AlertIcon, AlertTitle, Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { colors } from '../../utils/colors'
import { rounded, formatCurrencyAmount } from '../../utils/ui'
import { MdCheckCircle } from 'react-icons/md'
import { FaCaretSquareRight } from 'react-icons/fa'

interface IPricingBannerProps {
  isNewUser?: boolean
}

interface Product {
  name: string
  subTitle?: string
  description?: string
  amount: number | null
  url?: string
  benefits?: string[]
  callToAction?: string
}

const PricingBanner = React.memo(({ isNewUser }: IPricingBannerProps) => {
  const mockProducts: Omit<Product, 'type'>[] = [
    {
      name: 'Plan Básico',
      description: 'El plan perfecto para empresas y negocios con un número reducido de gastos a facturar.',
      amount: 300,
      benefits: ['Factura hasta 25 tickets de gastos al mes en automático desde WhatsApp.', 'Incluye 1 usuario facturando desde WhatsApp.', 'Factura a 1 Razón Social.', 'Accede al panel de control donde podrás ver y descargar todas las facturas generadas a tu negocio.'],
      url: process.env.REACT_APP_BASIC_PLAN_STRIPE_URL
    },
    {
      name: 'Plan Emprendedor',
      description: '¿Facturas más de 25 tickets? Contrata 50 tickets a facturar cada mes.',
      amount: 500,
      benefits: ['Factura hasta 50 tickets de gastos al mes en automático desde WhatsApp.', 'Incluye 1 usuario facturando desde WhatsApp.', 'Factura a 1 Razón Social.', 'Accede al panel de control donde podrás ver y descargar todas las facturas generadas por tu negocio.'],
      url: process.env.REACT_APP_ENTREPRENEUR_PLAN_STRIPE_URL
    },
    {
      subTitle: 'Personaliza tu suscripción',
      description: 'Si tienes más personas en tu equipo facturando gastos o tienes más de una razón social, personaliza tu plan.',
      name: 'Plan Empresarial',
      amount: null,
      url: process.env.REACT_APP_HELP_URL,
      benefits: ['Elige la cantidad de tickets de gastos a facturar en automático desde WhatsApp, desde 25 hasta 10,000 tickets al mes.', 'Elige la cantidad de usuarios que facturarán desde WhatsApp.', 'Elige la cantidad de razones sociales a las que facturas.', 'Accede al panel de control donde podrás ver y descargar todas las facturas generadas por tu empresa.'],
      callToAction: 'Contáctanos'
    }
  ]

  const Product = ({ name, amount, benefits = [], url, callToAction, subTitle, description }: Omit<Product, 'type'>) => (
    <SimpleGrid
      placeContent={{
        base: 'flex-end'
      }}
      templateRows={{
        base: '0.3fr 0.3fr 1.8fr 0.1fr'
      }}
      rowGap='1rem'
      minW={{
        base: 150,
        md: 195
      }}
      minH={{
        base: 320,
        md: '100%'
      }}
      bg={colors.white}
      rounded={rounded.container}
      borderWidth='1px'
      // borderColor={colors.tertiary[1]}
      boxShadow='md'
      transition='.5s ease'
      _hover={{
        boxShadow: 'xl'
      }}
      px={{
        base: '1rem',
        sm: '0.5rem'
      }}
      pt={{
        base: '1rem'
      }}
      pb={{
        base: '1rem',
        sm: '1.8rem',
        md: '2.2rem'
      }}
    >
      <SimpleGrid
        templateRows={{
          base: 'repeat(2, 1fr)'
        }}
        rowGap='.4rem'
        px='0.5rem'
      >
        <Flex
          height='100%'
          textAlign='center'
          alignItems='end'
        >
          <Text
            fontSize={{
              base: '1.2rem',
              lg: '1.3rem'
            }}
            fontWeight={700}
            height={{
              base: '1.2rem',
              lg: '1.3rem'
            }}
          >
            {typeof amount === 'number' ? formatCurrencyAmount(amount) : amount} {subTitle}
          </Text>
          <Text fontSize='0.8rem'>{typeof amount === 'number' ? <>\Mes</> : ''}</Text>
        </Flex>
        <Text
          textAlign='start'
          fontSize='1.3rem'
          fontWeight={700}
        >
          {name}
        </Text>
      </SimpleGrid>

      <Text
        fontSize='.9rem'
        px='0.3rem'
        mb='1rem'
        fontStyle='italic'
      >
        {description}
      </Text>

      <List
        alignItems='start'
        flexDir='column'
        flexGrow={1}
        justifyContent='start'
        width='100%'
        spacing={3}
        px='0.5rem'
      >
        {benefits.map((benefit, index) => (
          <ListItem
            flexDir='row'
            alignItems='center'
            fontSize='0.85rem'
            key={`${benefit}-${index}`}
          >
            <ListIcon
              as={MdCheckCircle}
              color='green.500'
            />
            {benefit}
          </ListItem>
        ))}
      </List>

      <Link
        w='100%'
        bg={colors.tertiary[3]}
        textColor={colors.text[1]}
        p='.4rem'
        rounded={rounded.input}
        fontSize='1rem'
        fontWeight={600}
        transition='.25s ease'
        textAlign='center'
        _hover={{
          boxShadow: 'lg'
        }}
        href={url}
      >
        {callToAction ? callToAction : isNewUser ? 'Registrarme' : 'Suscribirte'}
      </Link>
    </SimpleGrid>
  )

  return (
    <Box>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
          xl: 'repeat(3, 1fr)'
        }}
        w='full'
        justifyContent={{
          base: 'center',
          md: 'space-around'
        }}
        flexWrap='wrap'
        rowGap={{
          base: 4,
          md: 2
        }}
        columnGap={{
          sm: 4,
          md: 2,
          lg: 4,
          xl: 4
        }}
      >
        {mockProducts.map((product, index) => (
          <Product
            {...product}
            key={`${product.name}-${index}`}
          />
        ))}
      </Grid>

      <Alert
        mx='auto'
        borderRadius={rounded.container}
        p='0.7rem'
        bg={colors.tertiary[3]}
        mt='2rem'
        w='fit-content'
        status='info'
      >
        <AlertIcon />
        <AlertTitle> Todos nuestros precios incluyen IVA.</AlertTitle>
      </Alert>

      <Box mt='2rem'>
        <Link
          href={process.env.REACT_APP_HELP_URL}
          target='_blank'
          fontWeight={500}
        >
          ¿No encuentras un plan que te funcione? Contáctanos para hacer un plan personalizado a tu medida. <Icon as={FaCaretSquareRight} />
        </Link>
      </Box>
    </Box>
  )
})

export { PricingBanner }
