import { AbsoluteCenter, Center, Flex, Heading } from '@chakra-ui/react'
import { PageLayout } from '../components/Layout/PageLayout'

const Status404 = () => {
  return (
    <PageLayout>
      <Flex
        h='100vh'
        width='100%'
        justifyContent='center'
        alignItems='center'
        flexDir='column'
      >
        <Heading
          as='h1'
          bgGradient=''
          fontSize='4xl'
        >
          ERROR 404
        </Heading>

        <Heading
          as='h2'
          bgGradient=''
          fontSize='4xl'
        >
          Esta página no existe
        </Heading>
      </Flex>
    </PageLayout>
  )
}
export { Status404 }
