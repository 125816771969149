import { RequestHandler, IRequestHandler } from '../lib/RequestHandler'
import { GetSubscriptionStatusResponse } from '../ts/interfaces/request'
import { store } from '../store'
import { subscriptionSlice } from '../store/subscription/slice'

export const SubscriptionService = (props?: IRequestHandler) => ({
  requestHandler: RequestHandler(props || {}),

  async getSubscriptionStatus() {
    const subscription = (await this.requestHandler.get<GetSubscriptionStatusResponse>('subscriptions/status')).data

    store.dispatch(subscriptionSlice.actions.setSubscription(subscription.data))
    return subscription
  }
})
