import React from 'react'
import { Icon, Text, Flex } from '@chakra-ui/react'
import { inputHeight, inputWidth, fontSize, rounded, inputContainerHeight, inputPadding, field } from '../../utils/ui'
import { FiFile } from 'react-icons/fi'
import { useDropzone } from 'react-dropzone'
import { colors } from '../../utils/colors'

interface IFileFieldProps {
  name: string
  helperText?: string
  isRequired?: boolean
  label?: string
  isDisabled?: boolean
  accept?: Record<string, string[]>
  formValue?: string | null
  onChange?: (name: string, value: File[]) => void
}
const FileField = React.memo(({ helperText, label, isRequired, isDisabled, accept, name, onChange, formValue }: IFileFieldProps) => {
  const [fileName, setFileName] = React.useState<string>('')
  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setFileName(acceptedFiles[0].name)
    onChange && onChange(name, acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({ onDrop, accept: accept, disabled: isDisabled })

  return (
    <Flex
      {...getRootProps()}
      cursor='pointer'
      // bg='red'
      flexDir='column'
      justifyContent='start'
      alignItems='start'
      width={inputWidth}
      minH={inputContainerHeight}
      p={0}
      m={0}
    >
      <Text
        width='100%'
        opacity={0.5}
        fontWeight={500}
        padding={field.labelPadding}
        pointerEvents='none'
      >
        {label} {isRequired ? '*' : ''}
      </Text>
      <input
        type='file'
        {...getInputProps()}
      />
      <Flex
        alignItems='center'
        justifyContent='space-between'
        columnGap='0.1rem'
        px={inputPadding}
        rounded={rounded.input}
        height={inputHeight}
        width={field.inputWidth}
        border='1px solid #e5e7eb'
        _hover={{
          borderColor: '#d7d8db'
        }}
        flexWrap='nowrap'
        bg='transparent'
      >
        <Text
          opacity={formValue && !fileName ? 1 : 0.6}
          fontSize={fontSize.input}
          pointerEvents='none'
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
        >
          {formValue && !fileName ? formValue.slice(-25) : fileName || 'Seleccione o suelte aquí'}
        </Text>
        <Icon
          bg={colors.neutro[3]}
          as={FiFile}
          color={colors.neutralDark}
        />
      </Flex>
    </Flex>
  )
})

export { FileField }
