'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { UserForm } from '../components/ui/UserForm'
import { Flex } from '@chakra-ui/react'
import { Title } from '../components/fragments/Title'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { IconButton } from '../components/fragments/Button'
import { useNavigate } from 'react-router-dom'

const AddUserPage = () => {
  const navigate = useNavigate()
  return (
    <PageLayout>
      <Flex
        mt='4rem'
        mb='2rem'
        px='2rem'
        gap='1rem'
        justifyContent='center'
        alignItems='center'
      >
        <IconButton
          onClick={() => navigate(-1)}
          aria-label='go back button'
          icon={<FaArrowAltCircleLeft />}
        />
        <Title>Crear Usuario</Title>
      </Flex>
      <UserForm />
    </PageLayout>
  )
}

export { AddUserPage }
