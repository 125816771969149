'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { AbsoluteCenter, Heading, Text, Icon, Link, Flex } from '@chakra-ui/react'
import { colors } from '../utils/colors'
import { rounded } from '../utils/ui'
import { FaWhatsapp } from 'react-icons/fa'
import { useAppSelector } from '../store/hooks'

const WelcomeSubscriptionPage = () => {
  const user = useAppSelector((state) => state.user)

  const url = React.useMemo(() => `whatsapp://send?phone=${process.env.REACT_APP_ZUMMI_WHATSAPP_PHONE_NUMBER}&text=${encodeURIComponent(`¡Hola Zummi! Quiero facturar un ticket 🧾`)}`, [user])

  return (
    <PageLayout>
      <AbsoluteCenter>
        <Flex flexDir='column'>
          <Heading
            display='flex'
            flexDirection={{
              base: 'column',
              md: 'row'
            }}
            alignItems={{
              base: 'start',
              md: 'center'
            }}
            mt='1rem'
            as='h1'
            fontSize={{
              base: '2rem',
              md: ''
            }}
          >
            <Text>¡Bienvenido a</Text>
            <Text
              ml={{ md: '.5rem' }}
              className='text-colors-secondary-1'
            >
              Zumma!
            </Text>
          </Heading>

          <Heading
            as='h2'
            fontSize={{
              base: '1.2rem',
              md: ''
            }}
          >
            Tu pago ha sido procesado.
          </Heading>
          <Text
            mt='1rem'
            fontSize='1rem'
          >
            Ya puedes comenzar a facturar tus gastos a través de WhatsApp.
          </Text>

          <Link
            rounded={rounded.container}
            px={'.5rem'}
            py={'.5rem'}
            mt='1rem'
            bg={colors.whatsapp.light}
            as='a'
            href={url}
            textColor={colors.white}
            transition='.2s ease'
            boxShadow='sm'
            _hover={{
              boxShadow: 'xl',
              textDecoration: 'underline'
            }}
            width='fit-content'
            display='flex'
            flexDir='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Text
              fontWeight={600}
              fontSize={{
                base: '1.1rem'
              }}
            >
              Ir a facturar en WhatsApp&nbsp;
            </Text>
            <Icon
              ml='.6rem'
              fontWeight={700}
              fontSize='2rem'
              as={FaWhatsapp}
            />
          </Link>
        </Flex>
      </AbsoluteCenter>
    </PageLayout>
  )
}

export { WelcomeSubscriptionPage }
