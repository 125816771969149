export enum AnalyticType {
  QUANTITY_RECEIPTS_BY_USER = 'QUANTITY_RECEIPTS_BY_USER',
  QUANTITY_RECEIPTS_BY_CATEGORY = 'QUANTITY_RECEIPTS_BY_CATEGORY',
  QUANTITY_RECEIPTS_BY_DATE = 'QUANTITY_RECEIPTS_BY_DATE',
  QUANTITY_RECEIPTS_BY_PAYMENT_METHOD = 'QUANTITY_RECEIPTS_BY_PAYMENT_METHOD',
  TOTAL_EXPENSES_BY_USER = 'TOTAL_EXPENSES_BY_USER',
  TOTAL_EXPENSES_BY_CATEGORY = 'TOTAL_EXPENSES_BY_CATEGORY',
  TOTAL_EXPENSES_BY_DATE = 'TOTAL_EXPENSES_BY_DATE',
  TOTAL_EXPENSES_BY_PAYMENT_METHOD = 'TOTAL_EXPENSES_BY_PAYMENT_METHOD'
}
