import { RequestHandler, IRequestHandler } from '../lib/RequestHandler'
import { store } from '../store'
import { invoices } from '../store/invoices/slice'
import { Receipt, TaxInformation } from '../ts/interfaces/invoices'
import { CreateTaxInformationBody, GetInvoicesStatusResponse, GetTaxInformationListResponse, GetTaxInformationByIdResponse, UpdateTaxInformationBody, UpdateTaxInformationResponse, CreateTaxInformationResponse, GetAllReceiptsResponse, GetReceiptResponse, DeleteTaxInformationResponse, EnableTaxInformationResponse, GetInvoicesActiveResponse } from '../ts/interfaces/request'

export const InvoicesService = (props?: IRequestHandler) => ({
  requestHandler: RequestHandler(props),

  async getInvoicesStatus(props: { fetch_stats: boolean }) {
    try {
      const params: Record<string, string> = {
        fetch_stats: props.fetch_stats ? 'True' : 'False'
      }
      const urlSearchParams = new URLSearchParams(params)
      const response = await this.requestHandler.get<GetInvoicesStatusResponse>(`invoices/status?${urlSearchParams}`)
      if (response?.data?.data) {
        store.dispatch(invoices.actions.setInvoicesState(response.data.data))
        return response.data.data
      }
    } catch (error) {
      throw error
    }
  },
  async getInvoicesActive() {
    try {
      const response = await this.requestHandler.get<GetInvoicesActiveResponse>('invoices/active')
      if (response?.data?.data) {
        store.dispatch(invoices.actions.setInvoicesState(response.data.data))
        return response.data.data
      }
    } catch (error) {
      throw error
    }
  },
  async getTaxInformation() {
    try {
      const urlSearchParams = new URLSearchParams({
        retrieve_all: 'True'
      })
      const response = await this.requestHandler.get<GetTaxInformationListResponse>(`invoices/tax-information?${urlSearchParams}`)

      store.dispatch(invoices.actions.setTaxInformationList(response.data.data))
      return response.data.data
    } catch (error) {
      throw error
    }
  },
  async getTaxInformationById(id: TaxInformation['id']): Promise<TaxInformation> {
    try {
      const response = await this.requestHandler.get<GetTaxInformationByIdResponse>(`invoices/tax-information/${id}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  },
  async getAllReceipts() {
    try {
      const response = await this.requestHandler.get<GetAllReceiptsResponse>('invoices/receipts')
      store.dispatch(invoices.actions.setReceiptsList(response.data.data))
    } catch (error) {
      throw error
    }
  },
  async getReceipt(id: Receipt['receipt_id']) {
    try {
      const response = await this.requestHandler.get<GetReceiptResponse>(`invoices/receipt/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  async createTaxInformation(data: CreateTaxInformationBody) {
    return await this.requestHandler.post<CreateTaxInformationResponse>('invoices/tax-information', data)
  },
  async updateTaxInformation(data: UpdateTaxInformationBody) {
    return await this.requestHandler.patch<UpdateTaxInformationResponse>('invoices/tax-information', data)
  },
  async deleteTaxInformation(taxInformationId: TaxInformation['id']) {
    return await this.requestHandler.delete<DeleteTaxInformationResponse>(`invoices/tax-information/${taxInformationId}`)
  },
  async enableTaxInformation(taxInformationId: TaxInformation['id']) {
    return await this.requestHandler.post<EnableTaxInformationResponse>(`invoices/tax-information/enable/${taxInformationId}`)
  }
})
