import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  isFetching: boolean
  errorMessage?: string | null
  errorStatus?: number | null
}
const initialState: InitialState = {
  isFetching: false
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      return { ...state, isFetching: action.payload }
    },
    setError: (state, action: PayloadAction<Omit<InitialState, 'isFetching'> | null>) => {
      if (!action.payload)
        return {
          ...state,
          errorMessage: null,
          errorStatus: null
        }
      return { ...state, errorMessage: action.payload.errorMessage, errorStatus: action.payload.errorStatus }
    }
  }
})

export const { setIsFetching, setError } = uiSlice.actions

export default uiSlice.reducer
