import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.scss'
import './instrument'
import 'moment/dist/locale/es'

// Check the environment and load the Smartlook script if in production
//@ts-ignore
if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
  console.log('Using environment')
  const scriptContent = `
    window.smartlook||(function(d) {
      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', 'de1cf52e9686605cefe15e029c4349621d848f70', { region: 'eu' });
  `

  // Create a script element
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = scriptContent // Set the script content

  // Append the script to the head
  document.head.appendChild(script)
}

//@ts-ignore
createRoot(document.getElementById('root')).render(<App />)
