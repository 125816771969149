'use client'
import React from 'react'
import { Flex, SimpleGrid, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm } from '../../hooks/useForm'
import { Button } from '../fragments/Button'
import { colors } from '../../utils/colors'
import { PhoneField } from '../fields/PhoneFIeld/PhoneField'
import { TextField } from '../fields'
import { ProductType, UpdateUserForm, UserRole } from '../../ts/interfaces/interfaces'
import { useAppSelector } from '../../store/hooks'
import { UserService } from '../../services/User'

interface ITaxInformationForm {
  initialForm?: UpdateUserForm | null
  userRole?: UserRole
}

const UpdateUserSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  phone_number: Yup.string().min(6).required('Invalid phone number'),
  name: Yup.string().required('required name')
})

const UpdateUserInitialForm = {
  email: '',
  phone_number: '',
  name: ''
}

const UserForm = React.memo(({ initialForm, userRole }: ITaxInformationForm) => {
  const navigate = useNavigate()
  const subscription = useAppSelector((state) => state.subscription)

  const init = async () => {
    if (initialForm) return
    const seatProduct = subscription.products.find(({ type }) => type == ProductType.seat)
    const usersList = await UserService().getAllUsers()
    const enableUsers = usersList.filter(({ deleted_at }) => (!deleted_at ? true : false))

    if ((seatProduct && (seatProduct.limit?.max as number) <= enableUsers.length) || !seatProduct) {
      navigate(-1)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  const form = useForm({
    initialValues: initialForm ? initialForm : UpdateUserInitialForm,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: UpdateUserSchema,
    onSubmit: async (values) => {
      form.handleSetTouchedForm(values)

      try {
        let response

        if (initialForm)
          response = await UserService().updateUser({
            id: initialForm.id,
            ...values
          })
        else response = await UserService().createUser(values)

        toast({
          title: 'Usuario invitado exitosamente.',
          duration: 8000,
          isClosable: true
        })
        navigate(-1)
      } catch (error) {}
    }
  })

  const toast = useToast()

  return (
    <form
      className='pt-[1rem] flex flex-col gap-y-[1rem]'
      onSubmit={(e) => {
        e.preventDefault()
        form.handleSubmit()
      }}
    >
      <SimpleGrid
        placeItems='center'
        columns={{
          base: 1,
          md: 3
        }}
      >
        <TextField
          isRequired={true}
          label='Nombre'
          name='name'
          id='name'
          isDisabled={false}
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isInvalid={form.touched.name && form.errors.name ? true : false}
        />

        <TextField
          isReadOnly={form.isSubmitting || (userRole && userRole === UserRole.ADMIN)}
          isRequired={true}
          label='Email'
          name='email'
          id='email'
          isDisabled={false}
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isInvalid={form.touched.email && form.errors.email ? true : false}
        />

        <PhoneField
          isReadOnly={form.isSubmitting}
          isRequired={true}
          label='Número de WhatsApp'
          name='phone_number'
          id='phone_number'
          placeholder='+'
          isDisabled={false}
          value={form.values.phone_number}
          onChange={(phone) => form.setFieldValue('phone_number', phone)}
          onBlur={form.handleBlur}
          isInvalid={form.touched.phone_number && form.errors.phone_number ? true : false}
        />
      </SimpleGrid>
      <Flex
        w='100%'
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        mt={4}
        gap='1rem'
      >
        <Button
          isLoading={form.isSubmitting}
          type='submit'
        >
          {initialForm ? 'Actualizar usuario' : 'Agregar usuario'}
        </Button>
        <Button
          isLoading={form.isSubmitting}
          borderStyle='dashed'
          bg='transparent'
          borderColor={colors.text[4]}
          textColor={colors.text[2]}
          _hover={{
            borderColor: colors.text[1]
          }}
          _active={{
            bg: 'transparent'
          }}
          onClick={() => navigate(-1)}
        >
          Cancelar
        </Button>
      </Flex>
    </form>
  )
})

export { UserForm }
