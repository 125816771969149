import { SimpleGrid, Tabs, TabList, Tab, TabPanel, TabPanels, Flex } from '@chakra-ui/react'
import React from 'react'
import { BarChart } from './Charts/BarChart'
import { useAnalytics } from '../../hooks/useAnalytics'
import { AnalyticType } from '../../ts/interfaces/analytics'
import { Stats } from './Stats'
import { DateRangePicker } from '../fields/Date/DateRangePicker'
import moment from 'moment'
import { formatCurrencyAmount } from '../../utils/ui'
import { SelectField } from '../fields'
import { ExpensesFilterBy, ExpensesFilterByOptions } from '../../ts/interfaces/expenses'
import { useSize } from '@chakra-ui/react-use-size'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import { useUrlParamFilter } from '../../hooks/useDateUrlParamFilter'
const Analytics = React.memo(() => {
  const { dateValue: dateRangeValue, setDateValue: setDateRangeValue, filterBy, setFilterBy } = useUrlParamFilter({ useFilterBy: true, initialDateRange: [moment().startOf('month').toDate(), moment().toDate()] })
  const [tabIndex, setTabIndex] = React.useState<number>(0)

  const elementRef1 = React.useRef<HTMLDivElement>(null)
  const dimensions1 = useSize(elementRef1)

  const elementRef2 = React.useRef<HTMLDivElement>(null)
  const dimensions2 = useSize(elementRef2)

  const { data: quantityReceiptsPerCategoryData, isLoading: quantityReceiptsPerCategoryIsLoading } = useAnalytics({ type: AnalyticType.QUANTITY_RECEIPTS_BY_CATEGORY, dateRange: dateRangeValue, enable: tabIndex === 0, filterBy })
  const { data: quantityReceiptsPerDateData, isLoading: quantityReceiptsPerDateIsLoading } = useAnalytics({ type: AnalyticType.QUANTITY_RECEIPTS_BY_DATE, dateRange: dateRangeValue, enable: tabIndex === 0, filterBy })
  const { data: quantityReceiptsPerPaymentMethodData, isLoading: quantityReceiptsPerPaymentMethodIsLoading } = useAnalytics({ type: AnalyticType.QUANTITY_RECEIPTS_BY_PAYMENT_METHOD, dateRange: dateRangeValue, enable: tabIndex === 0, filterBy })
  const { data: quantityReceiptsPerUserData, isLoading: quantityReceiptsPerUserIsLoading } = useAnalytics({ type: AnalyticType.QUANTITY_RECEIPTS_BY_USER, dateRange: dateRangeValue, enable: tabIndex === 0, filterBy })

  const { data: totalSpentByUserData, isLoading: totalSpentByUserIsLoading } = useAnalytics({ type: AnalyticType.TOTAL_EXPENSES_BY_USER, dateRange: dateRangeValue, enable: tabIndex === 1, filterBy })
  const { data: totalSpentByDateData, isLoading: totalSpentByDateIsLoading } = useAnalytics({ type: AnalyticType.TOTAL_EXPENSES_BY_DATE, dateRange: dateRangeValue, enable: tabIndex === 1, filterBy })
  const { data: totalSpentByCategoryData, isLoading: totalSpentByCategoryIsLoading } = useAnalytics({ type: AnalyticType.TOTAL_EXPENSES_BY_CATEGORY, dateRange: dateRangeValue, enable: tabIndex === 1, filterBy })
  const { data: totalSpentByPaymentMethodData, isLoading: totalSpentByPaymentMethodIsLoading } = useAnalytics({ type: AnalyticType.TOTAL_EXPENSES_BY_PAYMENT_METHOD, dateRange: dateRangeValue, enable: tabIndex === 1, filterBy })

  return (
    <>
      <Flex
        alignItems='end'
        columnGap='1rem'
        flexWrap='wrap'
        justifyContent={{
          base: 'center',
          md: 'start'
        }}
      >
        <DateRangePicker
          label='Rango de fecha:'
          value={dateRangeValue}
          onChange={setDateRangeValue}
        />

        <SelectField
          isSearchable={false}
          isClearable={false}
          options={ExpensesFilterByOptions}
          value={filterBy}
          onChange={(_, value) => {
            setFilterBy(value as ExpensesFilterBy)
          }}
          label='Filtrar por fecha de:'
        />
      </Flex>
      <Stats
        filterBy={filterBy}
        dateRange={dateRangeValue}
        mb='2rem'
      />

      <Tabs
        index={tabIndex}
        onChange={setTabIndex}
      >
        <TabList>
          <Tab>Tickets</Tab>
          <Tab>Gastos</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <SimpleGrid
              placeItems='end'
              columns={{
                base: 1,
                lg: 2
              }}
              columnGap={{
                base: '2rem'
              }}
              rowGap={{
                base: '2rem'
              }}
              ref={elementRef1}
            >
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por usuario'
                serieTitle='Cantidad de tickets'
                data={quantityReceiptsPerUserData}
                isLoading={quantityReceiptsPerUserIsLoading}
              />
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por categoría'
                serieTitle='Cantidad de tickets'
                data={quantityReceiptsPerCategoryData}
                isLoading={quantityReceiptsPerCategoryIsLoading}
              />
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por día'
                serieTitle='Cantidad de tickets'
                data={quantityReceiptsPerDateData.map(({ label, value }) => ({ label: moment(label).format('DD-MMM-YY'), value: value }))}
                isLoading={quantityReceiptsPerDateIsLoading}
              />
              <BarChart
                contentBoxWidth={dimensions1?.width as number}
                title='# de tickets por forma de pago '
                serieTitle='Cantidad de tickets'
                data={quantityReceiptsPerPaymentMethodData}
                isLoading={quantityReceiptsPerPaymentMethodIsLoading}
              />
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <SimpleGrid
              columns={{
                base: 1,
                lg: 2
              }}
              columnGap={{
                base: '2rem'
              }}
              rowGap={{
                base: '2rem'
              }}
              ref={elementRef2}
            >
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por usuario'
                serieTitle='Total de gasto'
                data={totalSpentByUserData}
                isLoading={totalSpentByUserIsLoading}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por categoría'
                serieTitle='Total de gasto'
                data={totalSpentByCategoryData}
                isLoading={totalSpentByCategoryIsLoading}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por día'
                serieTitle='Total de gasto'
                data={totalSpentByDateData.map(({ label, value }) => ({ label: moment(label).format('DD-MMM-YY'), value: value }))}
                isLoading={totalSpentByDateIsLoading}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
              <BarChart
                contentBoxWidth={dimensions2?.width as number}
                title='Gasto por forma de pago'
                serieTitle='Total de gasto'
                data={totalSpentByPaymentMethodData}
                isLoading={totalSpentByPaymentMethodIsLoading}
                tooltip={{
                  pointFormat: '$<b>{point.y:.2f}</b>',
                  pointFormatter: function () {
                    //@ts-ignore
                    return `Total de gasto: <b>${formatCurrencyAmount(this.y)}</b>`
                  }
                }}
              />
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
})

export { Analytics }
