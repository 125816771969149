import { Button, Flex, InputRightAddon } from '@chakra-ui/react'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { TextField } from '../../fields'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { GetAllUsersParams } from '../../../ts/interfaces/request'

interface IUsersFilterProps {
  isLoading: boolean
  retrieveData: (params: GetAllUsersParams) => Promise<void>
}

export interface IUsersFilterRef {
  handleClearSearchParams: () => void
}

const UsersFilterForwards = React.forwardRef(({ retrieveData, isLoading }: IUsersFilterProps, ref) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const handleSearchUsers = async () => {
    if (searchTerm !== undefined) {
      searchParams.set('filter_by', searchTerm)
    }
    setSearchParams(searchParams)
    await retrieveData({ filter_by: searchTerm, setStateResult: false })
  }

  React.useEffect(() => {
    if (searchTerm === '') handleSearchUsers()
  }, [searchTerm])

  React.useEffect(() => {
    ;(async () => {
      setSearchTerm(searchParams.get('filter_by') || '')
      retrieveData({ filter_by: searchParams.get('filter_by'), setStateResult: false })
    })()
  }, [])

  const handleClearSearchParams = () => {
    searchParams.delete('filter_by')
    setSearchParams(searchParams)
    setSearchTerm('')
  }

  React.useImperativeHandle(
    ref,
    () => {
      return {
        handleClearSearchParams
      }
    },
    []
  )

  return (
    <Flex
      alignItems='center'
      justifyContent={{
        base: 'center',
        md: 'start'
      }}
      columnGap='1rem'
      flexDir='row'
      flexWrap='wrap'
      width='100%'
    >
      <TextField
        label='Filtrar por Nombre, Email o Número de teléfono'
        rightChildren={
          <InputRightAddon
            onClick={handleSearchUsers}
            p={0}
          >
            <Button>
              <FaMagnifyingGlass />
            </Button>
          </InputRightAddon>
        }
        onKeyDownCapture={(e) => {
          if (e.code === 'Enter') handleSearchUsers()
        }}
        placeholder='Buscar'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </Flex>
  )
})

const UsersFilter = React.memo(UsersFilterForwards)

export { UsersFilter }
