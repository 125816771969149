export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve((reader.result as string).split(',')[1])
    reader.onerror = reject
  })

export async function downloadFileFromSignedUrl(signedUrl: string, fileName: string) {
  try {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = signedUrl
    a.download = fileName

    document.body.appendChild(a)
    a.click()

    // Clean up
    window.URL.revokeObjectURL(signedUrl)
    document.body.removeChild(a)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}

export function isBase64(str?: string | null): boolean {
  if (!str) return false
  // Basic regex to check if the string could be Base64
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*?(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/

  // Check if the string matches the regex
  if (!base64Regex.test(str)) {
    return false
  }

  try {
    // Attempt to decode the string and check if it results in a valid string
    const decoded = atob(str)
    return btoa(decoded) === str
  } catch (err) {
    // If decoding fails, it's not a valid Base64
    return false
  }
}
